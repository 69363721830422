import React from "react";
import {
  Button,
  Header,
  Form,
  Icon,
  Image,
  Modal,
  Dropdown
} from "semantic-ui-react";
//import { stateOptions } from "../../../../commons/device_no";
//import DeviceDetails from "./DeviceDetails";

export default class AddDevice extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      addBoatModelOpen: false,
      device_eid: "",
      device_name: "",
      asset_name: "",
      asset_id: "",
      asset_eid: "",
      assetDropDownList: this.props.assetDropDownList,
    };
    this.handleDeviceEidChange = this.handleDeviceEidChange.bind(this);
    this.handleDeviceNameChange = this.handleDeviceNameChange.bind(this);
  }

  
  handleAddBoat = e => {
    e.preventDefault();
    console.log("handleAddBoat add device ",this.state);
    this.setState({
      addBoatModelOpen: false
    }); 
    this.props.callBackAddDevice(this.state, this.state.asset_id); 
  };

  handleOpenAddBoat = () => {
    this.setState({
      addBoatModelOpen: true
    });
  };

  handleCloseAddBoat = () => {
    this.setState({
      addBoatModelOpen: false
    });
  };
  

  handleDeviceNameChange(e) {
    this.setState({ device_name: e.target.value });
  }
  
  handleDeviceEidChange(e) {
    this.setState({ device_eid: e.target.value });
  }
  
  handleSelectFLeet = (e, data) => {
    const { key } = data.options.find(o => o.value === data.value);
    const { text } = data.options.find(o => o.value === data.value);
    console.log("Key", key);
    console.log("text", text);
    this.setState({ asset_name: text });
    this.setState({ asset_id: key });
  };
  render() {
    // const { boatName, deviceNo, addBoatModelOpen } = this.state;
    // const values = { boatName, deviceNo, addBoatModelOpen };
    return (
      <Modal
        size={"tiny"}
        dimmer={"blurring"}
        trigger={
          <Button 
          basic color="blue" 
          onClick={this.handleOpenAddBoat}>
            <Icon name="add" />
            Add a new Device
          </Button>
        }
        centered={false}
        open={this.state.addBoatModelOpen}
        onClose={this.handleCloseAddBoat}
        closeIcon
      >
        <Header>
          <Image 
          floated="left" 
          size="mini" 
          src="/boat-yatch.svg" 
            
          />
          Enter Device Details
        </Header>
        <Modal.Content>
          <Form>
            {/* <DeviceDetails
              deviceNoFunc={this.handleDeviceNoChange}
              deviceNo={this.state.deviceNo}
              boatNameFunc={this.handleBoatNameChange}
              boatName={this.state.boatName}
              values={values}
            /> */}
            {/* <Form.Field>
              <label>Fleet Name</label>
              <Dropdown
                placeholder="Existing Fleet"
                search
                selection
                options={stateOptions}
                onChange={this.handleSelectFLeet}
              />
            </Form.Field> */}
            <Form.Field>
              <label>Device EID</label>
              <input
                placeholder="Device EID"
                className="form-input"
                name={this.state.device_eid}
                defaultValue={this.state.device_eid}
                 onChange={this.handleDeviceEidChange}
              />
            </Form.Field>
            <Form.Field>
              <label>Name</label>
              <input
                placeholder="Name"
                className="form-input"
                name={this.state.device_name}
                defaultValue={this.state.device_name}
                onChange={this.handleDeviceNameChange}
              />
            </Form.Field>

            <Form.Field>
              <label>Fleet Name</label>
              <Dropdown
                placeholder="Existing Fleet"
                search
                selection
                options={this.state.assetDropDownList}
                className="form-input"
                searchQuery={this.state.searchQuery}
                text={this.state.assetDropDownList.text}
                onChange={this.handleSelectFLeet}
                // onSearchChange={this.onSearchChange}
              />
            </Form.Field>
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button.Group size="large">
            <Button
              basic
              color="red"
              type="submit"
              onClick={this.handleCloseAddBoat}
            >
              <Icon name="cancel" />
              Cancel
            </Button>
            <Button.Or />
            <Button
              basic
              color="blue"
              type="submit"
              onClick={this.handleAddBoat}
            >
              <Icon name="add" />
              Add
            </Button>
          </Button.Group>
        </Modal.Actions>
      </Modal>
    );
  }
}
