import React, { Component } from "react";
import {
  Form,
  Button,
  Icon,
  Checkbox,
  Dropdown,
  Divider,
  Label,
  Message
} from "semantic-ui-react";
import FleetDetails from "../Fleets/FleetDetails";
import DeviceDetails from "../Devices/DeviceDetails";
import { log } from "util";

class FleetnBoatOnBoarding extends Component {
  constructor(props) {
    super(props);
    this.state = {
      newFleetisChecked: this.props.values.newFleet,
      addBoatisChecked: this.props.values.newBoat,
      fleetName: this.props.values.fleetName,
      fleetNo: this.props.values.fleetNo,
      fleetManagerName: this.props.values.fleetManagerName,
      device_id: this.props.values.device_id,
      device_eid: this.props.values.device_eid,
      boatName: this.props.values.boatName,
      assetList: this.props.values.assetDropDownList,
      location: this.props.values.location,
      searchQuery: "",
      selected: null,
      fleetName_error: false,
      existing_fleetName_error: false,
      fleetManagerName_error: false,
      fleetName_error_message: "Please enter a valid fleet name",
      existing_fleetName_error_message: "Please enter a valid fleet name",
      device_eid_error: false,
      device_eid_error_message: "Please select a device from the list",
      fleetName_validated: false,
      fleetManagerName_validated: false,
      location_validated: false,
      boatName_validated: false,
      device_eid_validated: false,
      boatName_error: false,
      location_error: false,
      formValidationError: false,

      fleetManagerName_error_message: "Please enter a valid name",
      location_error_message: "Please enter a valid location",
      boatName_error_message: "Please enter a valid boat name"
    };
    this.handleNewFLeet = this.handleNewFLeet.bind(this);
    this.handleNewBoat = this.handleNewBoat.bind(this);
    this.handleFleetNameChange = this.handleFleetNameChange.bind(this);
    this.handleFleetNoChange = this.handleFleetNoChange.bind(this);
    this.handleFleetManagerNameChange = this.handleFleetManagerNameChange.bind(
      this
    );
    this.handleDeviceNoChange = this.handleDeviceNoChange.bind(this);
    this.handleBoatNameChange = this.handleBoatNameChange.bind(this);
    this.getData = this.getData.bind(this);
    this.handleLocationChange = this.handleLocationChange.bind(this);
    this.Continue = this.Continue.bind(this);
    // this.handlerFleetNameValidator = this.handlerFleetNameValidator.bind(this);
    // this.handlerManagerNameValidator = this.handlerManagerNameValidator.bind(
    //   this
    // );
    // this.handlerLocationValidator = this.handlerLocationValidator.bind(this);
  }

  componentDidMount() {
    console.log("asset list fetnbost", this.props);
  }
  handleFleetNameChange(e) {
    console.log("fleetName_validated", e);
    this.setState({
      fleetName: e,
      fleetName_validated: true,
      fleetName_error: false
    });
  }

  handleFleetNoChange(e) {
    this.setState({ fleetNo: e.target.value });
  }

  handleFleetManagerNameChange(e) {
    this.setState({
      fleetManagerName: e.target.value,
      fleetManagerName_validated: true,
      fleetManagerName_error: false
    });
  }

  handleDeviceNoChange(e) {
    console.log("devuce hange", e);
    this.setState({
      device_id: e.target.value,
      device_eid_error: false
    });
  }

  handleBoatNameChange(e) {
    this.setState({
      boatName: e.target.value,
      boatName_validated: true,
      boatName_error: false
    });
    this.validateForm();
  }
  handleLocationChange(e) {
    console.log("location", e.target.value);
    this.setState({
      location: e.target.value,
      location_validated: true,
      location_error: false
    });
  }
  async Continue(e) {
    e.preventDefault();
    console.log("fnb", this.state);

    await this.handlerFormValidator(e);

    // console.log("====================================");
    // console.log("fleetName_validated", this.state.fleetName_validated);
    // console.log(
    //   "fleetManagerName_validated",
    //   this.state.fleetManagerName_validated
    // );
    // console.log("location_validated", this.state.location_validated);
    // console.log("boatName_validated", this.state.boatName_validated);
    // console.log("device_eid_validated", this.state.device_eid_validated);
    // console.log("addBoatisChecked", this.state.addBoatisChecked);
    // console.log("boatName_validated value", this.state.boatName);
    // console.log("device_eid_validated vLUE", this.state.device_eid);
    // console.log("boatName_validated error", this.state.boatName_error);
    // console.log("device_eid_validated error", this.state.device_eid_error);
    // console.log("====================================");
    // console.log("====================================");
    // console.log(
    //   " this.state.formValidationError",
    //   this.state.formValidationError
    // );
    // console.log("====================================");

    if (!this.state.formValidationError) {
      this.props.nextStep();
      console.log(this.state);
      this.props.sendData(this.state);
    }
  }
  handlerFormValidator(e) {
    if (this.state.fleetName == "") {
      this.setState({
        fleetName_error: true,
        formValidationError: true
      });
    } else {
      this.setState({
        fleetName_error: false,
        formValidationError: false
      });
    }

    if (this.state.newFleetisChecked) {
      // if (this.state.fleetManagerName == "") {
      //   this.setState({
      //     fleetManagerName_error: true,
      //     formValidationError: true
      //   });
      // } else {
      //   this.setState({
      //     fleetManagerName_error: false,
      //     formValidationError: false
      //   });
      // }
      // if (this.state.location == "") {
      //   this.setState({
      //     location_error: true,
      //     formValidationError: true
      //   });
      // } else {
      //   this.setState({
      //     location_error: false,
      //     formValidationError: false
      //   });
      // }
    } else {
      this.setState({
        fleetName_validated: true,
        fleetManagerName_validated: true,
        location_validated: true
      });

      if (this.state.fleetName == "") {
        this.setState({
          existing_fleetName_error: true,
          formValidationError: true
        });
      } else {
        this.setState({
          existing_fleetName_error: false,
          formValidationError: false
        });
      }
    }

    if (this.state.addBoatisChecked) {
      if (this.state.device_eid == "") {
        this.setState({
          device_eid_error: true,
          formValidationError: true
        });
        console.log("  device_eid_error true");
      } else {
        this.setState({ device_eid_error: false, formValidationError: false });
        console.log("device_eid_error false");
      }

      // if (this.state.boatName == "") {
      //   this.setState({
      //     boatName_error: true,
      //     formValidationError: true
      //   });
      //   console.log("  boatName_error true");
      // } else {
      //   this.setState({ boatName_error: false, formValidationError: false });
      //   console.log("boatName_error false");
      // }
    } else {
      this.setState({
        boatName_validated: true,
        device_eid_validated: true
      });
    }
  }
  validateForm() {
    if (
      this.state.fleetName_validated &&
      // this.state.fleetManagerName_validated &&
      // this.state.location_validated &&
      // this.state.boatName_validated &&
      this.state.device_eid_validated
    ) {
      this.setState({
        formValidationError: false
      });
      // console.log(" Validated");
    } else {
      this.setState({
        formValidationError: true
      });
    }
  }

  back = e => {
    e.preventDefault();
    this.props.prevStep();
  };

  handleNewFLeet() {
    this.setState({ newFleetisChecked: !this.state.newFleetisChecked });
  }
  handleNewBoat() {
    this.setState({ addBoatisChecked: !this.state.addBoatisChecked });
  }

  handleExistingFleetChange = e => {
    console.log("Dropdown changed", e.target.value);
  };

  handleSelectFLeet = (e, data) => {
    //console.log("data", data);
    const { key } = data.options.find(o => o.value === data.value);
    const { text } = data.options.find(o => o.value === data.value);
    // console.log("Key", key);
    // console.log("value", data.value);
    // console.log("text", text);
    this.setState({
      fleetName: text,
      fleetNo: key,
      fleetName_error: false,
      fleetName_validated: true
    });
    //this.setState({ fleetNo: key });
  };

  onSearchChange = (e, data) => {
    this.setState({ searchQuery: data.searchQuery });
  };
  getData(key, text) {
    this.setState({
      device_id: key,
      device_eid: text,
      device_eid_error: false,
      device_eid_validated: true
    });
  }
  render() {
    const { values } = this.props;
    const { searchQuery, selected } = this.state;
    return (
      <Form color="green">
        <h1 className="ui centered">Enter Fleet & Boat Details</h1>
        <div>
          <Form.Field>
            <Checkbox
              toggle
              onChange={this.handleNewFLeet}
              checked={this.state.newFleetisChecked}
              label={{ children: "Do you want to add a new Fleet?" }}
            />
          </Form.Field>
          <div>
            {this.state.newFleetisChecked ? (
              <FleetDetails
                fleetNameFunc={this.handleFleetNameChange}
                fleetName={this.state.fleetName}
                fleetManagerNameFunc={this.handleFleetManagerNameChange}
                fleetManagerName={this.state.fleetManagerName}
                locationFunc={this.handleLocationChange}
                location={this.state.location}
                values={values}
              />
            ) : (
              <div>
                <Form.Field>
                  <label>Select a Fleet</label>
                </Form.Field>
                <Form.Field>
                  <Dropdown
                    placeholder="Existing Fleets"
                    search
                    selection
                    options={this.state.assetList}
                    className="form-input"
                    name={this.props.fleetName}
                    searchQuery={searchQuery}
                    text={this.props.values.assetList.text}
                    defaultValue={values.fleetNo}
                    onChange={this.handleSelectFLeet}
                    onSearchChange={this.onSearchChange}
                  />
                  {this.state.existing_fleetName_error ? (
                    <Label
                      //  basic
                      color="red"
                      pointing
                    >
                      {this.state.existing_fleetName_error_message}
                    </Label>
                  ) : null}
                </Form.Field>
              </div>
            )}
          </div>
        </div>

        <Divider section />

        <div>
          <Form.Field>
            <Checkbox
              toggle
              onChange={this.handleNewBoat}
              checked={this.state.addBoatisChecked}
              label={{ children: "Do you want to add a Boat?" }}
            />
          </Form.Field>

          {this.state.addBoatisChecked ? (
            <div>
              <DeviceDetails
                deviceNoFunc={this.handleDeviceNoChange}
                device_id={this.state.device_id}
                boatNameFunc={this.handleBoatNameChange}
                boatName={this.state.boatName}
                values={values}
                sendData={this.getData}
                device_eid_error={this.state.device_eid_error}
              />
            </div>
          ) : null}
        </div>

        {this.state.formValidationError ? (
          <Message negative>
            <Message.Header>Please check the following fields</Message.Header>
            {/* <p>Please insert username in the database</p> */}

            {!this.state.newFleetisChecked &&
            this.state.existing_fleetName_error ? (
              <p>{this.state.existing_fleetName_error_message}</p>
            ) : null}

            {this.state.newFleetisChecked && this.state.fleetName_error ? (
              <p>{this.state.fleetName_error_message}</p>
            ) : null}

            {/* {this.state.newFleetisChecked && this.state.location_error ? (
              <p>{this.state.location_error_message}</p>
            ) : null} */}

            {/* {this.state.newFleetisChecked &&this.state.fleetManagerName_error ? (
              <p>{this.state.fleetManagerName_error_message}</p>
            ) : null}   */}

            {this.state.device_eid_error ? ( //(this.addBoatisChecked) &&
              <p>{this.state.device_eid_error_message}</p>
            ) : null}

            {/* {!this.addBoatisChecked && this.state.boatName_error ? (    //this.addBoatisChecked &&
              <p>{this.state.boatName_error_message}</p>
            ) : null} */}
          </Message>
        ) : null}

        <div floated="right">
          <br />
          <Button basic color="red" onClick={this.back}>
            <Icon name="cancel" />
            Back
          </Button>

          <Button basic color="green" onClick={this.Continue}>
            Continue
            <Icon name="right arrow" />
          </Button>
          {/* {this.state.device_eid_error || this.state.boatName_error ? (
            <Label
              //  basic
              color="red"
              pointing
            >
              {this.state.device_eid_error_message}
            </Label>
          ) : null} */}
        </div>
      </Form>
    );
  }
}

export default FleetnBoatOnBoarding;
