import React from "react";
import {
  Button,
  Form,
  Grid,
  Header,
  Image,
  Icon,
  Modal,
  Checkbox,
  Dropdown,
  Divider,
  Message
} from "semantic-ui-react";
import UserDetails from "./UserDetails";

export default class AddUser extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      addUserModelOpen: false,
      addToFleet: false,
      firstName: "",
      lastName: "",
      phone: "000000",
      username: "",
      diffPassword: false,
      email: "",
      password: this.props.password, //"BoatSecure@1",
      fleetName: "",
      fleetNo: "nowNow",
      assetDropDownList: this.props.assetDropDownList,
      searchQuery: "",
      // emailValidated: false,
      // usernameValidated: false,
      // firstNameValidated: false,
      // lastNameValidated: false,
      // phoneNumberValidated: false,
      userList: this.props.values.userList,
      callFrom: "AddUser",
      emailValidated: true,
      usernameValidated: true,
      firstNameValidated: true,
      lastNameValidated: true,
      phoneNumberValidated: true,
      formValidationError: false,
      user_asset_list: [],
      email_exist: false,
      email_error: false,
      email_error_message: "Please enter an email address",
      username_exist: false,
      username_error: this.props.values.usernameValidated, //false,
      username_error_message: "Please enter a username",
      firstname_error: false,
      firstname_error_message: "Please enter a valid first name",
      lastname_error: false,
      lastname_error_message: "Please enter a valid last name",
      phonenumber_error: false,
      phonenumber_error_message: "Please enter a valid phone number"
    };
    this.handleAddtoFleetChecked = this.handleAddtoFleetChecked.bind(this); // set this, because you need get methods from CheckBox
    this.handleFirstNameChange = this.handleFirstNameChange.bind(this);
    this.handleLastNameChange = this.handleLastNameChange.bind(this);
    this.handlePhoneChange = this.handlePhoneChange.bind(this);
    this.handleUserNameChange = this.handleUserNameChange.bind(this);
    this.handleDiffPasswordChange = this.handleDiffPasswordChange.bind(this);
    this.handleEmailChange = this.handleEmailChange.bind(this);
    this.getData = this.getData.bind(this);
    this.handleAddUser = this.handleAddUser.bind(this);
    this.handleExistingUsername = this.handleExistingUsername.bind(this);
    this.handleExistingEmail = this.handleExistingEmail.bind(this);
    this.handlerFirstnameValidator = this.handlerFirstnameValidator.bind(this);
    this.handlerLastnameValidator = this.handlerLastnameValidator.bind(this);
    this.handlerPhoneNumberValidator = this.handlerPhoneNumberValidator.bind(
      this
    );
    this.handlerFormValidator = this.handlerFormValidator.bind(this);
  }

  componentDidMount() {
   // console.log(" props from add user", this.props);
  }
  handleAddtoFleetChecked() {
    this.setState({ addToFleet: !this.state.addToFleet });
  }

  handleFirstNameChange(e) {
    //   console.log("useroboardi", e.target.value);
    this.setState({
      firstName: e.target.value,
      firstNameValidated: true
    });
  }

  handleLastNameChange(e) {
    this.setState({
      lastName: e.target.value,
      lastNameValidated: true
    });
  }

  handlePhoneChange(e) {
    let phone_number = e.target.value.replace("+64", "");
    console.log("phone_number ", phone_number);
    //phone_number = phone_number.r
    phone_number = "+64" + phone_number;
    console.log("phone_number 2 ", phone_number);
    this.setState({
      phone: phone_number,
      phoneNumberValidated: true
    });
  }

  handleUserNameChange(e) {
    this.setState({
      username: e.target.value,
      usernameValidated: true
    });
  }

  handleDiffPasswordChange(e) {
    this.setState({
      diffPassword: e.target.value
    });
  }
  handlePPasswordChange(e) {
    this.setState({
      password: e.target.value
    });
  }
  handleEmailChange(e) {
    //  console.log("emilsdf add user detail" ,e.target.value);
    this.setState({
      email: e.target.value,
      emailValidated: true
    });
  }

  getData(val) {
    //  console.log("values from sesu se", val);
    this.setState({
      password: val.password,
      diffPassword: val.newPassword
    });
  }

  async handleAddUser(e) {
    e.preventDefault();
    console.log("add user button");
    console.log(this.state);

    await this.handlerFormValidator(e);

    if (!this.state.formValidationError) {
      // console.log(this.state);
      this.setState({
        addUserModelOpen: false
      });
      this.props.callBackAddUser(this.state);
    }
  }

  handleOpenAddUser = () => {
    this.setState({
      addUserModelOpen: true,
      firstName: "",
      lastName: "",
      phone: "000000",
      username: "",
      diffPassword: false,
      email: ""
    });
  };

  handleCloseAddUser = () => {
    this.setState({
      addUserModelOpen: false
    });
  };

  handleAddFLeet = (e, data) => {
    console.log("e", e);
    console.log("data", data);
    console.log("data", data.value);
    this.setState({ user_asset_list: data.value });
  };

  validateForm() {
    // console.log("firstNameValidated",this.state.firstNameValidated);
    // console.log("lastNameValidated",this.state.lastNameValidated );
    // console.log("emailValidated",this.state.emailValidated );
    // console.log("phonevalidation",this.state.phoneNumberValidated );
    // console.log("usernamevalidation", this.state.usernameValidated);
    // console.log("passwordvalidation",this.state.phoneNumberValidated );

    return (
      this.state.firstNameValidated &&
      this.state.lastNameValidated &&
      this.state.emailValidated &&
      this.state.usernameValidated &&
      this.state.phoneNumberValidated
    );
  }

  handlerFormValidator(e) {
    if (this.state.firstName != "") {
      this.handlerFirstnameValidator(this.state.firstName);
      console.log("firstNameValidated 1", this.state.firstNameValidated);
    } else {
      this.setState({
        formValidationError: true,
        firstNameValidated: false
      });
      console.log("firstNameValidated 2", this.state.firstNameValidated);
    }
    if (this.state.lastName != "") {
      this.handlerLastnameValidator(this.state.lastName);
      console.log("lastNameValidated 1", this.state.lastNameValidated);
    } else {
      this.setState({
        formValidationError: true,
        lastNameValidated: false
      });
      console.log("lastNameValidated 2", this.state.lastNameValidated);
    }
    if (this.state.email != "") {
      this.handleExistingEmail(this.state.email);
      console.log("email 1", this.state.emailValidated);
    } else {
      this.setState({
        formValidationError: true,
        emailValidated: false
      });
      console.log("email 2", this.state.emailValidated);
    }
    if (this.state.phone.length > 0) {
      this.handlerPhoneNumberValidator(this.state.phone);
      console.log("phone 1", this.state.phoneNumberValidated);
      console.log("phone 1", this.state.phone);
    } else {
      this.setState({
        phone: "00000"
      });
    }
    if (this.state.username != "") {
      this.handleExistingUsername(this.state.username);
      console.log("username 1", this.state.usernameValidated);
    } else {
      this.setState({
        formValidationError: true,
        usernameValidated: false
      });
      console.log("username 2", this.state.usernameValidated);
      console.log("username 2 valye", this.state.username);
    }
    console.log("formValidationError", this.state.formValidationError);
  }

  validateForm() {
    console.log(" validateForm");
    //  console.log("firstNameValidated",this.state.firstNameValidated );
    // console.log("lastNameValidated",this.state.lastNameValidated );
    // console.log("emailValidated",this.state.emailValidated );
    // console.log("phonevalidation",this.state.phoneNumberValidated );
    // console.log("usernamevalidation", this.state.usernameValidated);
    // console.log("passwordvalidation",this.state.phoneNumberValidated );

    if (
      this.state.firstNameValidated &&
      this.state.lastNameValidated &&
      this.state.emailValidated &&
      this.state.phoneNumberValidated &&
      this.state.usernameValidated &&
      this.state.phoneNumberValidated
    ) {
      this.setState({
        formValidationError: false
      });
      // console.log(" Validated");
    } else {
      this.setState({
        formValidationError: true
      });
      //  console.log("NOt Validated");
    }
  }

  handleExistingEmail(e) {
    console.log("email handler", e);
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(e)) {
      for (let i = 0; i < this.state.userList.data.length; i++) {
        if (e == this.state.userList.data[i].attributes.data.email) {
          this.setState({
            emailValidated: false,
            formValidationError: true,
            email_error_message: "This email is already registered"
          });
          break;
        } else {
          this.setState({ emailValidated: true, formValidationError: false });
        }
      }
    } else {
      this.setState({
        emailValidated: false,
        formValidationError: true,
        email_error_message: "Please enter a correct email"
      });
    }
  }

  handleExistingUsername(e) {
    if (e.length > 2) {
      for (let i = 0; i < this.state.userList.data.length; i++) {
        if (
          (this.state.userList.data[i].attributes.username ||
            this.state.userList.data[i].attributes.username2) == e
        ) {
          this.setState({
            usernameValidated: false,
            formValidationError: true,
            username_error_message: "This username is already registered"
          });
          break;
        } else {
          this.setState({
            usernameValidated: true,
            formValidationError: false
          });
        }
      }
    } else {
      this.setState({
        usernameValidated: false,
        formValidationError: true,
        username_error_message: "Username should be minimum 3 characters long"
      });
    }
  }

  handlerFirstnameValidator(e) {
    if (e.length > 0) {
      this.setState({ firstNameValidated: true, formValidationError: false });
    } else {
      this.setState({ firstNameValidated: false, formValidationError: true });
    }
  }

  handlerLastnameValidator(e) {
    if (e.length > 0) {
      this.setState({ lastNameValidated: true, formValidationError: false });
    } else {
      this.setState({
        lastNameValidated: false,
        formValidationError: true
      });
    }
  }

  isNumber(n) {
    return !isNaN(parseFloat(n)) && !isNaN(n - 0);
  }

  handlerPhoneNumberValidator(e) {
    let isNumber = this.isNumber(e);
    if (e.length > 0) {
      if (isNumber) {
        this.setState({
          phoneNumberValidated: true,
          formValidationError: false
        });
      } else {
        this.setState({
          phoneNumberValidated: false,
          formValidationError: true
        });
      }
    }
  }
  render() {
    const {
      firstName,
      lastName,
      phone,
      username,
      diffPassword,
      email,
      password,
      newFleet,
      fleetName,
      fleetNo,
      addUserModelOpen,
      userList
    } = this.state;
    const values = {
      firstName,
      lastName,
      phone,
      username,
      diffPassword,
      email,
      password,
      newFleet,
      fleetName,
      fleetNo,
      userList
    };
    return (
      <Modal
        size={"tiny"}
        dimmer={"blurring"}
        trigger={
          <Button basic color="blue" onClick={this.handleOpenAddUser}>
            <Icon name="add" />
            Add new User
          </Button>
        }
        centered={false}
        open={addUserModelOpen}
        onClose={this.handleCloseAddUser}
        closeIcon
      >
        <Header>
          <Image floated="left" size="mini" src="/captain.svg" />
          User Details
        </Header>
        <Modal.Content>
          <Form>
            <UserDetails
              firstNameFunc={this.handleFirstNameChange}
              firstName={this.state.firstName}
              lastNameFunc={this.handleLastNameChange}
              lastName={this.state.lastName}
              emailFunc={this.handleEmailChange}
              email={this.state.email}
              phoneFunc={this.handlePhoneChange}
              phoneNumber={this.state.phone}
              userNameFunc={this.handleUserNameChange}
              userName={this.state.username}
              diffPasswordFunc={this.handleDiffPasswordChange}
              diffPassword={this.state.diffPassword}
              values={values}
              // controlFunc={this.handlePPasswordChange}
              sendData={this.getData}
            />

            <Divider section />
            <Form.Field>
              <Checkbox
                toggle
                onChange={this.handleAddtoFleetChecked}
                label={{ children: "Add to an existing Fleet?" }}
              />
            </Form.Field>

            {this.state.addToFleet ? (
              <Form.Field>
                <Dropdown
                  placeholder="Existing Fleet"
                  search
                  multiple
                  selection
                  options={this.state.assetDropDownList}
                  className="form-input"
                  searchQuery={this.state.searchQuery}
                  text={this.props.assetDropDownList.text}
                  onChange={this.handleAddFLeet}
                />
              </Form.Field>
            ) : null}
          </Form>

          {this.state.formValidationError ? (
            <Message negative>
              <Message.Header>Please check the following fields</Message.Header>

              {!this.state.firstNameValidated ? (
                <p>{this.state.firstname_error_message}</p>
              ) : null}
              {!this.state.lastNameValidated ? (
                <p>{this.state.lastname_error_message}</p>
              ) : null}
              {!this.state.phoneNumberValidated ? (
                <p>{this.state.phonenumber_error_message}</p>
              ) : null}
              {!this.state.emailValidated ? (
                <p>{this.state.email_error_message}</p>
              ) : null}
              {!this.state.usernameValidated ? (
                <p>{this.state.username_error_message}</p>
              ) : null}
            </Message>
          ) : null}
        </Modal.Content>
        <Modal.Actions>
          <Button.Group size="large">
            <Button
              basic
              color="red"
              type="submit"
              onClick={this.handleCloseAddUser}
            >
              <Icon name="cancel" />
              Cancel
            </Button>
            <Button.Or />
            <Button
              basic
              color="green"
              type="submit"
              onClick={this.handleAddUser}
              // disabled={!this.validateForm()}
            >
              <Icon name="add" />
              Add
            </Button>
          </Button.Group>
        </Modal.Actions>
      </Modal>
    );
  }
}
