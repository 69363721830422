import React from "react";
import {
  Button,
  Form,
  Grid,
  Header,
  Image,
  Modal,
  Checkbox,
  Dropdown,
  Icon,
  Input,
  Label,
  Message
} from "semantic-ui-react";
import { stateOptions } from "../../../../commons/device_no";
import { log } from "util";

export default class EditUser extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      addOrRemoveFleet: false,
      editUserModelOpen: this.props.editUserModalOpen,
      id: this.props.selectedUser.id,
      username: this.props.selectedUser.username,
      phone: this.props.selectedUser.phone.replace("+64", ""),
      email: this.props.selectedUser.email,
      fullname: this.props.selectedUser.fullname,
      firstname: this.props.selectedUser.firstname,
      lastname: this.props.selectedUser.lastname,
      assetDropDownList: this.props.assetDropDownList,
      searchQuery: "",
      firstname_error: false,
      firstname_error_message: "Please enter a valid first name",
      lastname_error: false,
      lastname_error_message: "Please enter a valid last name",
      phonenumber_error: false,
      phonenumber_error_message: "Please enter a valid phone number",
      email_error: false,
      //asset_user_list: this.props.asset_user_list,
      asset_user_list: this.props.selectedUser.user_asset_user_list,
      selected_asset_user_list: this.props.selectedUser.user_asset_user_list,
      user_asset_list: this.props.selectedUser.user_asset_user_list,
      
      formValidationError: false,
    };
    this.handleAddOrRemoveFleet = this.handleAddOrRemoveFleet.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handlerFirstnameValidator = this.handlerFirstnameValidator.bind(this);
    this.handlerLastnameValidator = this.handlerLastnameValidator.bind(this);
    this.handlerPhoneNumberValidator = this.handlerPhoneNumberValidator.bind(
      this
    );
    this.validateForm = this.validateForm.bind(this);
  }
  componentDidMount() {
    // console.log(" props from   to edit user ", this.props);
    // console.log(" states from   to edit user ", this.state);
     if (this.state.username == "") {
      this.setState({ email_error: true });
    }
  }
  handleInputChange(event) {
    const target = event.target;
    let value = target.value;
    const name = target.name;
    if (name == "phone") {
      value = "+64" + value;
    }
    console.log("====================================");
    console.log("target", target);
    console.log("value", value);
    console.log("name", name);

    console.log("====================================");
    this.setState({
      [name]: value
    });
  }

  handleAddOrRemoveFleet() {
    console.log("handle checkbix");
    this.setState({ addOrRemoveFleet: !this.state.addOrRemoveFleet });
  }

  handleEditUser = e => {
    e.preventDefault();
    console.log("edit user button", e);
    console.log(this.state);
    // if (this.state.firstname == "") {
    //   this.setState({ firstname_error: true });
    // }
    // if (this.state.lastname == "") {
    //   this.setState({ lastname_error: true });
    // }
    
    // console.log("this.state.firstname_error",this.state.firstname_error);
    // console.log("this.state.lastname_error",this.state.lastname_error);
    // console.log("this.state.phonenumber_error",this.state.phonenumber_error);

    // if (
    //   !this.state.firstname_error &&
    //   !this.state.lastname_error &&
    //   !this.state.phonenumber_error 
    // ) {
      this.setState({ editUserModelOpen: false });
      this.props.closeModal();
      this.props.callBackEditUser(this.state);
  //  }
  };

  handleOpenEditUser = () => {
    this.setState({
      editUserModelOpen: true
    });
  };

  handleCloseEditUser = () => {
    this.setState({
      editUserModelOpen: false
    });
    this.props.closeModal();
  };

  handleSelectFLeet = (e, data) => {
    console.log("data.value", data.value);
    // console.log("====================================");
    // console.log("data", data);
    // console.log("data value", data.value);
    // console.log("====================================");

    // console.log("====================================");
    // console.log("asset_user_List lenght", data.value.length);
    // console.log("====================================");


//this.setState({user_asset_list: data.value})
this.setState({user_asset_list: data.value})
 //const { key } = data.options.find(o => o.value === data.value);
// const { text } = data.options.find(o => o.value === data.value);
// console.log("Key", key);
// console.log("text", text);
// this.setState({ asset_name: text });
// this.setState({ asset_id: key });
  };

  handlerFirstnameValidator(e) {
    console.log("handlerFirstnameValidator", e.target.value);
    if (e.target.value != "") {
      this.setState({ firstname_error: false,
        formValidationError: false, });
      this.handleInputChange(e);
    } else {
      this.setState({ firstname_error: true,
        formValidationError: true, });
    }
  }

  handlerLastnameValidator(e) {
    console.log("handlerLastnameValidator", e.target.value);

    if (e.target.value.length != "") {
      this.setState({ lastname_error: false,
        formValidationError: false, });
      this.handleInputChange(e);
    } else {
      this.setState({ lastname_error: true,
        formValidationError: true, });
    }
  }

  isNumber(n) {
    return !isNaN(parseFloat(n)) && !isNaN(n - 0);
  }

  handlerPhoneNumberValidator(e) {
    console.log("handlerPhoneNumberValidator", typeof e.target.value);
    let isNumber = this.isNumber(e.target.value);
    console.log("isNumber", isNumber);
    console.log("e.target.value", e.target.value);
if (!e.target.value==""){
  if (isNumber ) {//&& e.target.value.length > 5
    this.setState({ phonenumber_error: false,
    
      formValidationError: false, });
    this.handleInputChange(e);
  } else {
    this.setState({ phonenumber_error: true,
      formValidationError: true, });
  }
}

   
  }

  validateForm() {
    // console.log("firstNameValidated",this.state.firstNameValidated);
    // console.log("lastNameValidated",this.state.lastNameValidated );
    // console.log("emailValidated",this.state.emailValidated );
    // console.log("phonevalidation",this.state.phoneNumberValidated );
    // console.log("usernamevalidation", this.state.usernameValidated);
    // console.log("passwordvalidation",this.state.phoneNumberValidated );
    
    if (this.state.firstname != "") {
      this.setState({ firstname_error: false,
        formValidationError: false, });
    } else {
      this.setState({ firstname_error: true,
        formValidationError: true, });
    }
    if (this.state.lastname != "") {
      this.setState({ lastname_error: false,
        formValidationError: false, });
    } else {
      this.setState({ lastname_error: true,
        formValidationError: true, });
    }

    let isNumber = this.isNumber(this.state.phone);
    console.log("isNumber", isNumber);
    if (isNumber && this.state.phone.length > 5) {
      this.setState({ phonenumber_error: false,
      
        formValidationError: false, });
    } else {
      this.setState({ phonenumber_error: true,
        formValidationError: true, });
    }
  }




  render() {
    const { editUserModelOpen } = this.state;
    return (
      <Modal
        size={"tiny"}
        dimmer={"blurring"}
        centered={false}
        open={this.state.editUserModelOpen}
        onClose={this.handleCloseEditUser}
        closeIcon
      >
        <Header>
          <Image floated="left" size="mini" src="/captain.svg" />
          Edit User Details
        </Header>
        <Modal.Content>
          {this.state.email_error ? (
            <Message negative>
              <Message.Header>Cannot update this record!</Message.Header>
              <p>Please insert username in the database</p>
            </Message>
          ) : null}
          <Form>
            <Form.Field>
              <label>First Name</label>
              <input
                placeholder="First Name"
                name="firstname"
                defaultValue={this.props.selectedUser.firstname}
                  onChange={this.handleInputChange}
               // onBlur={this.handlerFirstnameValidator}
              />
              {this.state.firstname_error ? (
                <Label
                  //  basic
                  color="red"
                  pointing
                >
                  {this.state.firstname_error_message}
                </Label>
              ) : null}
            </Form.Field>
            <Form.Field>
              <label>Last Name</label>
              <input
                placeholder="Last Name"
                name="lastname"
                defaultValue={this.props.selectedUser.lastname}
                  onChange={this.handleInputChange}
                //onBlur={this.handlerLastnameValidator}
              />
              {this.state.lastname_error ? (
                <Label
                  //  basic
                  color="red"
                  pointing
                >
                  {this.state.lastname_error_message}
                </Label>
              ) : null}
            </Form.Field>
            <Form.Field>
              <label>phone</label>
              <Input
                label="+64"
                placeholder="Phone"
                name="phone"
                defaultValue={this.props.selectedUser.phone.replace("+64", "")}
                // onChange={this.handleInputChange}
                onBlur={this.handlerPhoneNumberValidator}
              />
              {this.state.phonenumber_error ? (
                <Label
                  //  basic
                  color="red"
                  pointing
                >
                  {this.state.phonenumber_error_message}
                </Label>
              ) : null}
            </Form.Field>
            <Form.Field>
              <label>Email</label>
              <input
                placeholder="Email"
                name="email"
                readOnly
                value={this.props.selectedUser.email}
                onChange={this.handleInputChange}
              />
            </Form.Field>
            <Form.Field>
              <label>Username</label>
              <input
                placeholder="Username"
                name="username"
                readOnly
                value={this.props.selectedUser.username}
                onChange={this.handleInputChange}
              />
            </Form.Field>

            <br />
            <Form.Field>
              <Checkbox
                toggle
                onChange={this.handleAddOrRemoveFleet}
                label={{ children: "Add or remove Fleet?" }}
              />
            </Form.Field>

            {this.state.addOrRemoveFleet ? (
              <Form.Field>
                <Dropdown
                  placeholder="Existing Fleet"
                  search
                  multiple
                  selection
                  options={this.state.assetDropDownList}
                  className="form-input"
                  searchQuery={this.state.searchQuery}
                  text={this.props.assetDropDownList.text}
                  defaultValue={this.props.selectedUser.user_asset_user_list}
                  onChange={this.handleSelectFLeet}
                />
              </Form.Field>
            ) : null}
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button.Group size="large">
            <Button
              basic
              color="red"
              type="submit"
              onClick={this.handleCloseEditUser}
            >
              <Icon name={"cancel"} />
              Cancel
            </Button>
            <Button.Or />
            <Button
              basic
              color="green"
              type="submit"
              onClick={this.handleEditUser}
              disabled={this.state.email_error}
            >
              <Icon name={"edit"} />
              Update
            </Button>
          </Button.Group>
        </Modal.Actions>
      </Modal>
    );
  }
}
