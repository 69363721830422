import React, { Component } from 'react';
import { Segment, Button, Grid, Image  } from 'semantic-ui-react';
import {Link}   from 'react-router-dom'; 
 
export default class AdminLandingPage extends Component {
     
    state = { visible: false }
    handleButtonClick = () => this.setState({ visible: !this.state.visible })
    handleSidebarHide = () => this.setState({ visible: false })
    handleDrawer = () => { this.refs.customDrawerReference.handleToggle(); }

    handleClick = () => {
        console.log('this is:', this.state);
    }
    backButtonHandler(e) {
        this.props.callBack(e)
    }
   
    render() {
        return (
            <Segment
                color='blue'
                padded='very'
                raised size='mini'>
              
                <Link
                    to="/"
                    onClick={() => this.backButtonHandler(5)}
                    
                >
                    
                    <Button
                        basic color='blue'
                        floated='right'
                    >
                        On Boarding
                     </Button>

                </Link>

                <br />      <br />   <br />   <br />
                <Grid centered columns={3} >
                    <Grid.Row centered columns={3} >

                        <Grid.Column>
                        
                            <Link
                                to="/"
                                onClick={() => this.backButtonHandler(2)}>
                                <Image
                                    src='/captain.svg'
                                    size='medium'
                                    target='_blank'
                                    label={{
                                        color: 'blue',
                                        content: 'Users',
                                        ribbon: true
                                    }}
                                />
                                
                            </Link>
                        </Grid.Column>
                        <Grid.Column>
                            
                            <Link
                                to="/"
                                onClick={() => this.backButtonHandler(3)}>
                                <Image
                                    src='/fleet.svg'
                                    size='medium'
                                    target='_blank'
                                    label={{
                                        color: 'blue',
                                        content: 'Fleets',
                                        ribbon: true
                                    }}
                               />

                            </Link>
                            
                        </Grid.Column>

                        <Grid.Column>
                            <Link
                                to="/"
                                onClick={() => this.backButtonHandler(4)}>
                                <Image
                                    src='/boat-yatch.svg'
                                    size='medium'
                                    target='_blank'
                                    label={{
                                        color: 'blue',
                                        content: 'Boats',
                                        ribbon: true
                                    }}
                               />

                            </Link>
                            
                        </Grid.Column>
                       
                    </Grid.Row>
                </Grid>
                {/* <br />
                <Image src='https://react.semantic-ui.com/images/wireframe/paragraph.png' />
                <br />
                <Image src='https://react.semantic-ui.com/images/wireframe/paragraph.png' />
                <br /> */}
            </Segment>
            
            
            )
    }


}

