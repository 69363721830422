export default {
    s3: {
        REGION: "ap-southeast-2",
        BUCKET: "oatecuredmin-20181106130730-deployment"
    },
    apiGateway: {
        REGION: "ap-southeast-2",
        URL:   "https://6n4puphab0.execute-api.ap-southeast-2.amazonaws.com/prod"
    },
    cognito: {
        REGION: "ap-southeast-2",
        USER_POOL_ID: "ap-southeast-2_CO2EPTNHC",
        APP_CLIENT_ID: "59n8l5cnt2um061ktv9juif5rp",
        IDENTITY_POOL_ID: "ap-southeast-2:9c94ab1e-0eca-47ac-ad98-198e477eb5ee"
    },
    appDetails: {
        APP_ID :"c2029214-dab5-40d2-90e5-d3f72a3e93ae"
    } 
};
