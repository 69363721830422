import React, { Component } from "react";
import { Form, Button, Message, Icon } from "semantic-ui-react";
import UserDetails from "../Users/UserDetails";
import Password from "../../commons/Password";

class UserOnBoarding extends Component {
  constructor(props) {
    super(props);
    this.state = {
      firstName: this.props.values.firstName,
      lastName: this.props.values.lastName,
      phoneNumber: this.props.values.phone,
      userName: this.props.values.username,
      diffPassword: this.props.values.diffPassword,
      email: this.props.values.email,
      password: this.props.values.password,
      userList: this.props.values.userList,
      emailValidated: true,
      usernameValidated: true,
      firstNameValidated: true,
      lastNameValidated: true,
      phoneNumberValidated: true,
      formValidationError: false,

      email_exist: false,
      email_error: false,
      email_error_message: "Please enter an email address",
      username_exist: false,
      username_error: this.props.values.usernameValidated, //false,
      username_error_message: "Please enter a username",
      firstname_error: false,
      firstname_error_message: "Please enter a valid first name",
      lastname_error: false,
      lastname_error_message: "Please enter a valid last name",
      phonenumber_error: false,
      phonenumber_error_message: "Please enter a valid phone number"
    };
    this.handleFirstNameChange = this.handleFirstNameChange.bind(this);
    this.handleLastNameChange = this.handleLastNameChange.bind(this);
    this.handlePhoneChange = this.handlePhoneChange.bind(this);
    this.handleUserNameChange = this.handleUserNameChange.bind(this);
    this.handleDiffPasswordChange = this.handleDiffPasswordChange.bind(this);
    this.handleEmailChange = this.handleEmailChange.bind(this);
    this.getPassword = this.getPassword.bind(this);
    this.getDiffPassword = this.getDiffPassword.bind(this);
    // this.handleChange = this.handleChange.bind(this);
    this.handlerContinue = this.handlerContinue.bind(this);
    this.handleExistingUsername = this.handleExistingUsername.bind(this);
    this.handleExistingEmail = this.handleExistingEmail.bind(this);
    this.handlerFirstnameValidator = this.handlerFirstnameValidator.bind(this);
    this.handlerLastnameValidator = this.handlerLastnameValidator.bind(this);
    this.handlerPhoneNumberValidator = this.handlerPhoneNumberValidator.bind(
      this
    );
    this.handlerFormValidator = this.handlerFormValidator.bind(this);
  }

  backButtonHandler = e => {
    this.props.callBackButton(1);
  };

 async handlerContinue  (e)   {
    e.preventDefault();
    console.log(this.state);

    // this.setState(state => ({
    //   usernameValidated: !state.usernameValidated
    // }));
    
    await this.handlerFormValidator(e);
    
    if (!this.state.formValidationError){
      this.props.nextStep();
    console.log(this.state);
    this.props.sendData(this.state);
    }
  };

handlerFormValidator(e){
  if (this.state.firstName != "") {
    this.handlerFirstnameValidator(this.state.firstName);
    console.log("firstNameValidated 1",this.state.firstNameValidated)
  } else {
    this.setState({
      formValidationError: true,
      firstNameValidated: false
    });
    console.log("firstNameValidated 2",this.state.firstNameValidated)
  }
  if (this.state.lastName != "") {
    this.handlerLastnameValidator(this.state.lastName);
    console.log("lastNameValidated 1",this.state.lastNameValidated)
  } else {
   
    this.setState({
      formValidationError: true,
      lastNameValidated: false
    });
    console.log("lastNameValidated 2",this.state.lastNameValidated)
  }
  if (this.state.email != "") {
    this.handleExistingEmail(this.state.email);
    console.log("email 1",this.state.emailValidated)
  } else {
    this.setState({
      formValidationError: true,
      emailValidated:false
    });
    console.log("email 2",this.state.emailValidated)
  }
  if (this.state.phoneNumber.length > 0) {
   
    this.handlerPhoneNumberValidator(this.state.phoneNumber);
    console.log("phone 1",this.state.phoneNumberValidated)
    console.log("phone 1",this.state.phoneNumber)
  }
  if (!this.state.username != "") {
   
    this.handleExistingUsername(this.state.userName);
    console.log("username 1",this.state.usernameValidated)
  } else {
   
    this.setState({
      formValidationError: true,
      usernameValidated:false
    });
    console.log("username 2",this.state.usernameValidated)
  }
console.log("formValidationError",this.state.formValidationError);
}

  handleFirstNameChange(e) {
    this.setState({
      firstName: e.target.value,
      firstNameValidated: true
    });
  }

  handleLastNameChange(e) {
    this.setState({
      lastName: e.target.value,
      lastNameValidated: true
    });
  }

  handlePhoneChange(e) {
    let phone_number = e.target.value.replace("+64", "");
    console.log("phone_number ", phone_number);
    //phone_number = phone_number.r
    phone_number = "+64" + phone_number;
    console.log("phone_number 2 ", phone_number);
    this.setState({
      phoneNumber: phone_number,
      phoneNumberValidated: true
    });
  }

  handleUserNameChange(e) {
    this.setState({
      userName: e.target.value,
      usernameValidated: true
    });
    this.validateForm();
  }

  handleDiffPasswordChange(e) {
    this.setState({
      diffPassword: e.target.value
    });
  }
  handlePPasswordChange(e) {
    this.setState({
      password: e.target.value
    });
  }
  handleEmailChange(e) {
    this.setState({
      email: e.target.value,
      emailValidated: true
    });
    this.validateForm();
  }

  getPassword(val) {
    this.setState({
      password: val
      //  diffPassword: val.newPassword
    });
  }
  getDiffPassword(val) {
    this.setState({
      //  password: val
      diffPassword: val
    });
  }

  validateForm() {
     console.log(" validateForm");
    //  console.log("firstNameValidated",this.state.firstNameValidated );
    // console.log("lastNameValidated",this.state.lastNameValidated );
    // console.log("emailValidated",this.state.emailValidated );
    // console.log("phonevalidation",this.state.phoneNumberValidated );
    // console.log("usernamevalidation", this.state.usernameValidated);
    // console.log("passwordvalidation",this.state.phoneNumberValidated );

    if (
      this.state.firstNameValidated &&
      this.state.lastNameValidated &&
      this.state.emailValidated &&
      this.state.phoneNumberValidated &&
      this.state.usernameValidated &&
      this.state.phoneNumberValidated
    ){
      this.setState({
        formValidationError: false,
      });
     // console.log(" Validated");
    }
    else{
      this.setState({
        formValidationError: true,
      });
    //  console.log("NOt Validated");
    };
  }

  handleExistingEmail(e) {
console.log("email handler", e)
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(e)) {
      for (let i = 0; i < this.state.userList.data.length; i++) {
        if (
          e == this.state.userList.data[i].attributes.data.email
        ) {
          this.setState({
            emailValidated: false,
            formValidationError: true,
            email_error_message: "This email is already registered"
          });
          break;
        } else {
          this.setState({ emailValidated: true, formValidationError: false });
        }
      }
      
    } else {
      this.setState({
        emailValidated: false,
        formValidationError: true,
        email_error_message: "Please enter a correct email"
      });
    }
  }

  handleExistingUsername(e) {
  //  console.log("username handler", e)
    if (e.length > 2) {
      for (let i = 0; i < this.state.userList.data.length; i++) {             
        if (
          (this.state.userList.data[i].attributes.username ||
            this.state.userList.data[i].attributes.username2) == e
        ) {
          this.setState({
            usernameValidated: false,
            formValidationError: true,
            username_error_message: "This username is already registered"
          });
          break;
        } else {
          this.setState({
            usernameValidated: true,
            formValidationError: false
          });
        }
      }      
    } else {
      this.setState({
        usernameValidated: false,
        formValidationError: true,
        username_error_message: "Username should be minimum 3 characters long"
      });
    }
  }

  handlerFirstnameValidator(e) {
    if (e.length > 0) {
      this.setState({ firstNameValidated: true, formValidationError: false });
    } else {
      this.setState({ firstNameValidated: false, formValidationError: true });
    }
  }

  handlerLastnameValidator(e) {
    if (e.length > 0) {
      this.setState({ lastNameValidated: true, formValidationError: false });
    } else {
      this.setState({
        lastNameValidated: false,
        formValidationError: true
      });
    }
  }

  isNumber(n) {
    return !isNaN(parseFloat(n)) && !isNaN(n - 0);
  }

  handlerPhoneNumberValidator(e) {
    let isNumber = this.isNumber(e);
    if (e.length > 0) {
      if (isNumber) {
        this.setState({
          phoneNumberValidated: true,
          formValidationError: false
        });
      } else {
        this.setState({
          phoneNumberValidated: false,
          formValidationError: true
        });
      }
    }
  }
  render() {
    const { values } = this.props;

    return (
      <Form color="green">
        <h1 className="ui centered">Enter User Details</h1>
        <UserDetails
          firstNameFunc={this.handleFirstNameChange}
          firstName={this.state.firstName}
          lastNameFunc={this.handleLastNameChange}
          lastName={this.state.lastName}
          emailFunc={this.handleEmailChange}
          email={this.state.email}
          phoneFunc={this.handlePhoneChange}
          phoneNumber={this.state.phoneNumber}
          userNameFunc={this.handleUserNameChange}
          userName={this.state.userName}
          diffPasswordFunc={this.handleDiffPasswordChange}
          diffPassword={this.state.diffPassword}
          values={values}
          sendPassword={this.getPassword}
          sendDiffPassword={this.getDiffPassword}
        />
        {this.state.formValidationError ? (
          <Message negative>
            <Message.Header>Please check the following fields</Message.Header>
          
              {!this.state.firstNameValidated
                ?(<p>{this.state.firstname_error_message}</p>) 
                
                : null}
              {!this.state.lastNameValidated
                ? (
                  <p>{ this.state.lastname_error_message}</p>
                 )
                : null}
              {!this.state.phoneNumberValidated
                ? (<p>{this.state.phonenumber_error_message}</p>)
                : null}
              {!this.state.emailValidated
                ? (<p>{this.state.email_error_message}</p>)
                : null}
              {!this.state.usernameValidated
                ? (<p>{this.state.username_error_message}</p>)
                : null}
           
          </Message>
        ) : null}
        <div floated="right">
          <br />
          <Button basic color="red" onClick={this.backButtonHandler}>
            <Icon name="cancel" />
            Cancel
          </Button>

          <Button
            basic
            color="green"
            onClick={this.handlerContinue}
            // disabled={!this.validateForm()}
          >
            Continue
            <Icon name="right arrow" />
          </Button>
        </div>
      </Form>
    );
  }
}

export default UserOnBoarding;
