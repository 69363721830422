import React, { Component } from "react";
import {
  Button,
  Card,
  Image,
  Segment,
  Icon,
  Menu,
  Input,
  Header
} from "semantic-ui-react";
import { Link } from "react-router-dom";
import AddFleet from "./AddFleet";
import EditFleet from "./EditFleet";
import DeleteModal from "../../commons/DeleteModal";
export default class FleetList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      assetList: this.props.values.assetList,
      assetDropDownList: this.props.values.assetDropDownList,
      editFleetModalOpen: false,
      deleteFleetModalOpen: false,
      selectedFleet: {
        asset_name: "",
        asset_id: "",
        asset_eid: "",
        first_contact: "",
        location: "",
        app_id: ""
      }
    };
    this.singleFleetCard = this.singleFleetCard.bind(this);
    this.handleEditFleetModalOpen = this.handleEditFleetModalOpen.bind(this);
    this.handleEditFleetModalClose = this.handleEditFleetModalClose.bind(this);
    this.updateFleetHandler = this.updateFleetHandler.bind(this);
    this.addFleetHandler = this.addFleetHandler.bind(this);
    this.handleDeleteFleetModalOpen = this.handleDeleteFleetModalOpen.bind(
      this
    );
    this.handleDeleteFleetModalClose = this.handleDeleteFleetModalClose.bind(
      this
    );
    this.deleteFleetHandler = this.deleteFleetHandler.bind(this);
  }
  componentDidMount() {
    //  console.log(" props from homelayout to Fleet List", this.props);
  }
  handleButtonClick = () => this.setState({ visible: !this.state.visible });
  handleSidebarHide = () => this.setState({ visible: false });

  backButtonHandler(e) {
    this.props.callBack(e);
  }

  handleDeleteFleetModalOpen = values => {
    this.setState({
      deleteFleetModalOpen: true,
      selectedFleet: {
        asset_name: values.asset_name,
        asset_id: values.id,
        asset_eid: values.asset_eid,
        first_contact: values.first_contact,
        location: values.location,
        app_id: values.app_id
      }
    });
  };

  handleDeleteFleetModalClose = () => {
    this.setState({ deleteFleetModalOpen: false });
  };

  deleteFleetHandler(e) {
    //  console.log("deleteFleetHandler", e);
    this.props.callBackDeleteFleet(e);
  }

  handleEditFleetModalOpen(values) {
    //console.log("testubg", values);
    this.setState({
      editFleetModalOpen: true,
      selectedFleet: {
        asset_name: values.asset_name,
        asset_id: values.id,
        asset_eid: values.asset_eid,
        first_contact: values.first_contact,
        location: values.location,
        app_id: values.app_id
      }
    });
  }
  handleEditFleetModalClose() {
    this.setState({ editFleetModalOpen: false });
  }

  updateFleetHandler(e) {
    //   console.log("updateFleetHandler", e);
    this.props.callBackEditFleet(e);
  }
  addFleetHandler(e) {
    //   console.log("addFleetHandler fllet list", e);
    this.props.callBackAddFleet(e);
  }

  singleFleetCard = item => {
    //console.log("item fleetlist", item);
    const id = item.id;
    const asset_id = item.id;
    let asset_name = item.attributes.name;
    const asset_eid = item.attributes.asset_eid;
    const app_id = item.attributes.app_id;
    const first_contact = item.attributes.first_contact;
    const location = item.attributes.location;
    const { assetList, assetDropDownList } = this.state;
    const style = {
      opacity: 1
    };
    let values = {
      id,
      asset_name,
      asset_id,
      asset_eid,
      asset_name,
      app_id,
      first_contact,
      location,
      assetList,
      assetDropDownList
    };
    return (
      <Card color="blue" key={item.id}>
        <Card.Content>
          <Image floated="right" size="mini" src="/fleet.svg" />
          <Card.Header>
            {" "}
            <label> Fleet Name: </label>
            {item.attributes.name}
          </Card.Header>
          <Card.Description>
            <label>
              {" "}
              <strong>First Contact: </strong>{" "}
            </label>{" "}
            <strong>{item.attributes.first_contact}</strong>
            <br />
            <label>
              {" "}
              <strong>Location: </strong>{" "}
            </label>{" "}
            {item.attributes.location}
          </Card.Description>
        </Card.Content>
        <Card.Content extra>
          <div className="ui two buttons">
            <Button
              basic
              color="green"
              fluid
              size="large"
              onClick={() => this.handleEditFleetModalOpen(values)}
            >
              <Icon name="edit" />
              Edit
            </Button>
            <Button
              basic
              color="red"
              fluid
              size="large"
              onClick={() => this.handleDeleteFleetModalOpen(values)}
            >
              <Icon name="delete" />
              Delete
            </Button>
          </div>
        </Card.Content>
      </Card>
    );
  };

  handleChange = (e, data) => {
    let searchQuery = data.value;
    this.setState({ deviceList: this.props.values.assetList });
    let searchList = this.props.values.assetList.data;
    let searchListResponse = [];
    for (let i = 0; i < searchList.length; i++) {
      let name =
        searchList[i].attributes.name == null
          ? ""
          : searchList[i].attributes.name;
      let first_contact =
        searchList[i].attributes.first_contact == null
          ? ""
          : searchList[i].attributes.first_contact;
      let location =
        searchList[i].attributes.location == null
          ? ""
          : searchList[i].attributes.location;
      if (
        name.toLowerCase().includes(searchQuery.toLowerCase()) ||
        first_contact.toLowerCase().includes(searchQuery.toLowerCase()) ||
        location.toLowerCase().includes(searchQuery.toLowerCase())
      ) {
        searchListResponse.push(searchList[i]);
      }
    }
    this.setState({ assetList: searchListResponse });
  };
  render() {
    const { assetList, assetDropDownList } = this.state;
    const values = {
      assetList,
      assetDropDownList
    };
    const menustyle = { border: "1px  solid #2185d0" };
    const dropdownstyle = { width: "50%" };
    const SegmentStyle = { height: "100%" };
    //  var assetEntries = this.state.assetList.data;
    var assetEntries =
      this.state.assetList.data == null
        ? this.state.assetList
        : this.state.assetList.data;
    var listofAssetItems = assetEntries.map(this.singleFleetCard);
    return (
      <Segment color="blue" padded="very" raised size="mini">
        <Header color="blue">
          <h3>Fleet List</h3>
        </Header>
        <Menu className="header-menu" size="tiny" style={menustyle}>
          <Menu.Item position="left">
            <AddFleet floated="left" callBackAddFleet={this.addFleetHandler} />
          </Menu.Item>

          <Menu.Item style={dropdownstyle}>
            <Input
              icon="search"
              placeholder="Search..."
              basic="true"
              color="blue"
              fluid
              style={menustyle}
              onChange={this.handleChange}
            />
          </Menu.Item>

          <Menu.Item position="right">
            <Link to="/">
              <Button
                basic
                color="blue"
                floated="right"
                onClick={() => this.backButtonHandler(1)}
              >
                {" "}
                <Icon name="left arrow" />
                Back
              </Button>
            </Link>
          </Menu.Item>
        </Menu>
        <br /> <br />
        <Card.Group> {listofAssetItems} </Card.Group>
        {this.state.editFleetModalOpen ? (
          <EditFleet
            closeModal={this.handleEditFleetModalClose}
            editFleetModalOpen={this.state.editFleetModalOpen}
            selectedFleet={this.state.selectedFleet}
            assetDropDownList={this.state.assetDropDownList}
            callBackEditFleet={this.updateFleetHandler}
            values={values}
          />
        ) : null}
        {this.state.deleteFleetModalOpen ? (
          <DeleteModal
            closeModal={this.handleDeleteFleetModalClose}
            deleteModalOpen={this.state.deleteFleetModalOpen}
            selectedItemName={this.state.selectedFleet.asset_name}
            selectedItemId={this.state.selectedFleet.asset_id}
            callBackDelete={this.deleteFleetHandler}
            additionalMessage=""
            additionalMessageEnabled={false}
            values={values}
          />
        ) : null}
      </Segment>
    );
  }
}
