import React, { Component } from "react";
import {
  Button,
  Card,
  Image,
  Segment,
  Icon,
  Dropdown,
  Message,
  Menu,
  Input,
  Header
} from "semantic-ui-react";
import { Link } from "react-router-dom";
import AddUser from "./AddUser";
import EditUser from "./EditUser";
import DeleteModal from "../../commons/DeleteModal";

export default class UserList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      userList: this.props.values.userList,
      assetList: this.props.values.assetList,
      asset_user_list: this.props.values.asset_user_list,
      assetDropDownList: this.props.values.assetDropDownList,
      editUserModalOpen: false,
      deleteUserModalOpen: false,
      additionalMessageEnabled: false,
      selectedUser: {
        id: "",
        username: "",
        phone: "",
        email: "",
        fullname: "",
        firstname: "",
        lastname: "",
        user_asset_user_list: []
      }
    };
    this.handleEditUserModalClose = this.handleEditUserModalClose.bind(this);
    this.singleUserCard = this.singleUserCard.bind(this);
    this.handleEditUserModalOpen = this.handleEditUserModalOpen.bind(this);
    this.updateUserHandler = this.updateUserHandler.bind(this);
    this.addUserHandler = this.addUserHandler.bind(this);
    this.handleDeleteUserModalOpen = this.handleDeleteUserModalOpen.bind(this);
    this.handleDeleteUserModalClose = this.handleDeleteUserModalClose.bind(
      this
    );
    this.deleteUserHandler = this.deleteUserHandler.bind(this);
  }
  componentDidMount() {
    // console.log(" props from homelayout to user List", this.props);
  }
  handleButtonClick = () => this.setState({ visible: !this.state.visible });
  handleSidebarHide = () => this.setState({ visible: false });
  handleDrawer = () => {
    this.refs.customDrawerReference.handleToggle();
  };

  handleClick = () => {
    console.log("this is:", this.state);
  };

  backButtonHandler(e) {
    this.props.callBack(e);
  }

  handleDeleteUserModalOpen = values => {
    this.setState({
      deleteUserModalOpen: true,
      selectedUser: {
        id: values.id,
        username: values.username,
        phone: values.phone,
        email: values.email,
        fullname: values.fullname,
        firstname: values.firstname,
        lastname: values.lastname
      }
    });
    if (values.username == "") {
      this.setState({ additionalMessageEnabled: true });
    } else {
      this.setState({ additionalMessageEnabled: false });
    }
  };

  handleDeleteUserModalClose = () => {
    this.setState({ deleteUserModalOpen: false });
  };

  deleteUserHandler(e) {
    console.log("deleteUserHandler", e);
    this.props.callBackDeleteUser(e);
  }

  handleEditUserModalClose() {
    this.setState({ editUserModalOpen: false });
  }

  updateUserHandler(e) {
    this.props.callBackEditUser(e);
  }
  addUserHandler(e) {
    // console.log("addFleetHandler fllet list", e);
    this.props.callBackAddUser(e);
  }

  handleEditUserModalOpen(values) {
    // console.log("testubg", values);
    // console.log("testubg id", values.username);

    let single_user_asset_list = [];
    let selected_user_asset_list = [];
    //let whole_asset_user_list = this.state.asset_user_list;
    let whole_asset_user_list = this.props.values.asset_user_list;
    console.log("whole_asset_user_list",whole_asset_user_list)
    for (let i = 0; i < whole_asset_user_list.data.length; i++) {
      if (whole_asset_user_list.data[i].attributes.user_id == values.id) {
        selected_user_asset_list.push(
          whole_asset_user_list.data[i].attributes.asset_eid
        );
      }
    }
    this.setState({
      editUserModalOpen: true,
      selectedUser: {
        id: values.id,
        username: values.username,
        phone: values.phone,
        email: values.email,
        fullname: values.fullname,
        firstname: values.firstname,
        lastname: values.lastname,
        user_asset_user_list: selected_user_asset_list
      }
    });
  }

  handleChange = (e, data) => {
    let searchQuery = data.value;
    this.setState({ deviceList: this.props.values.userList });
    let searchList = this.props.values.userList.data;
    let searchListResponse = [];
    for (let i = 0; i < searchList.length; i++) {
      let email =
        searchList[i].attributes.email == null
          ? ""
          : searchList[i].attributes.email;
      let firstname =
        searchList[i].attributes.firstname == null
          ? ""
          : searchList[i].attributes.firstname;
      let lastname =
        searchList[i].attributes.lastname == null
          ? ""
          : searchList[i].attributes.lastname;
      let phone =
        searchList[i].attributes.phone == null
          ? ""
          : searchList[i].attributes.phone;
      let username =
        searchList[i].attributes.username == null
          ? ""
          : searchList[i].attributes.username;
      let username2 =
        searchList[i].attributes.username2 == null
          ? ""
          : searchList[i].attributes.username2;
      if (
        email.toLowerCase().includes(searchQuery.toLowerCase()) ||
        firstname.toLowerCase().includes(searchQuery.toLowerCase()) ||
        lastname.toLowerCase().includes(searchQuery.toLowerCase()) ||
        phone.toLowerCase().includes(searchQuery.toLowerCase()) ||
        username.toLowerCase().includes(searchQuery.toLowerCase()) ||
        username2.toLowerCase().includes(searchQuery.toLowerCase())
      ) {
        searchListResponse.push(searchList[i]);
      }
    }
    this.setState({ userList: searchListResponse });
  };

  singleUserCard = item => {
    let singleUserCardItem =
      item.attributes == null ? item.data : item.attributes;
    //  console.log("singleUserCardItem", singleUserCardItem);

    const id = item.id == null ? item.sub : item.id;
    // let usernameOne =item.attributes.username2 === null ? "" : item.attributes.username;
    // item.attributes.username === null ? "" : item.attributes.username;
    // let username =
    // item.attributes.username === null ? "" : item.attributes.username;

    let usernameOne =
      item.attributes.username2 == null
        ? item.attributes.username
        : item.attributes.username2;
    let username =
      usernameOne == null
        ? singleUserCardItem.data.username == null
          ? ""
          : singleUserCardItem.data.username
        : usernameOne;
    const phone =
      item.attributes.phone == null
        ? singleUserCardItem.data.phone_number == null
          ? ""
          : singleUserCardItem.data.phone_number
        : item.attributes.phone;
    const firstname =
      item.attributes.firstname == null
        ? singleUserCardItem.data.given_name == null
          ? ""
          : singleUserCardItem.data.given_name
        : item.attributes.firstname;
    const lastname =
      item.attributes.lastname == null
        ? singleUserCardItem.data.family_name == null
          ? ""
          : singleUserCardItem.data.family_name
        : item.attributes.lastname;
    const email =
      item.attributes.email == null
        ? singleUserCardItem.data.email == null
          ? ""
          : singleUserCardItem.data.email
        : item.attributes.email;

    let fullname = firstname + " " + lastname;
    const { userList } = this.state;
    let values = {
      id,
      username,
      phone,
      email,
      fullname,
      firstname,
      lastname
    };
    return (
      <Card color="blue" key={item.id}>
        <Card.Content>
          <Image floated="right" size="mini" src="/captain.svg" />
          <Card.Header>
            {" "}
            <label>First Name: </label> {firstname}
            <br />
            <label>Family Name: </label> {lastname}
          </Card.Header>
          <Card.Meta>
            {" "}
            <label>Username: </label>
            {username}
          </Card.Meta>
        </Card.Content>
        <Card.Content extra>
          <a>
            <Icon name="mail" />
            {email}
          </a>
          <Icon name="phone" /> {phone}
        </Card.Content>

        <Card.Content extra>
          <div className="ui two buttons">
            <Button
              basic
              color="green"
              fluid
              size="large"
              onClick={() => this.handleEditUserModalOpen(values)}
            >
              <Icon name="edit" />
              Edit
            </Button>
            <Button
              basic
              color="red"
              fluid
              size="large"
              onClick={() => this.handleDeleteUserModalOpen(values)}
            >
              <Icon name="user delete" />
              Delete
            </Button>
          </div>
        </Card.Content>
      </Card>
    );
  };

  noItems = () => {
    return <Message floating>No Users!</Message>;
  };

  render() {
    // var userEntries = this.state.userList.data;
    var userEntries =
      this.state.userList.data == null
        ? this.state.userList
        : this.state.userList.data;
    if (userEntries.length > 0) {
      var listUserItems = userEntries.map(this.singleUserCard);
    } else {
      // var listUserItems =this.noItems;
    }
    const { values } = this.props;
    const menustyle = { border: "1px  solid #2185d0" };
    const dropdownstyle = { width: "50%" };
    const SegmentStyle = { height: "100%" };
    return (
      <Segment color="blue" padded="very" raised size="mini">
        <Header color="blue">
          <h3>User List</h3>
        </Header>
        <Menu className="header-menu" size="tiny" style={menustyle}>
          <Menu.Item position="left">
            <AddUser
              assetDropDownList={this.state.assetDropDownList}
              callBackAddUser={this.addUserHandler}
              password={this.props.values.password}
              values={values}
            />
          </Menu.Item>

          <Menu.Item style={dropdownstyle}>
            <Input
              icon="search"
              placeholder="Search..."
              basic="true"
              color="blue"
              fluid
              style={menustyle}
              onChange={this.handleChange}
            />
          </Menu.Item>

          <Menu.Item position="right">
            <Link to="/">
              <Button
                basic
                color="blue"
                floated="right"
                onClick={() => this.backButtonHandler(1)}
              >
                {" "}
                <Icon name="left arrow" />
                Back
              </Button>
            </Link>
          </Menu.Item>
        </Menu>
        <br /> <br />
        <Card.Group> {listUserItems} </Card.Group>
        {this.state.editUserModalOpen ? (
          <EditUser
            closeModal={this.handleEditUserModalClose}
            editUserModalOpen={this.state.editUserModalOpen}
            selectedUser={this.state.selectedUser}
            assetDropDownList={this.state.assetDropDownList}
            callBackEditUser={this.updateUserHandler}
            //values={values}
          />
        ) : null}
        {this.state.deleteUserModalOpen ? (
          <DeleteModal
            closeModal={this.handleDeleteUserModalClose}
            deleteModalOpen={this.state.deleteUserModalOpen}
            selectedItemName={this.state.selectedUser.username}
            selectedItemId={this.state.selectedUser.id}
            callBackDelete={this.deleteUserHandler}
            additionalMessage="This user cannot be disable in Cognito, do you want to remove from the list?"
            additionalMessageEnabled={this.state.additionalMessageEnabled}
            values={values}
          />
        ) : null}
      </Segment>
    );
  }
}
