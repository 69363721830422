import React from "react";
import { Form, Dropdown, Label } from "semantic-ui-react";
import PropTypes from "prop-types";

class DeviceDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      device_id: "",
      device_eid: "",
      boatName: "",
      deviceList: this.props.values.deviceDropDownList,
      // boatName_error: false,
      // boatName_error_message: "Please enter a valid boat name",
      device_eid_error: this.props.device_eid_error,
      device_eid_error_message: "Please select a device from the list"
    };
    this.handlerBoatNameValidator = this.handlerBoatNameValidator.bind(this);
  }

  componentDidMount() {
    console.log("device details", this.props);
  }
  handlerBoatNameValidator(e) {
    console.log("handlerBoatNameValidator", e.target.value);
    // if (e.target.value.length > 0) {
    //   this.setState({ boatName_error: false });
    //   this.props.boatNameFunc(e);
    // } else {
    //   this.setState({ boatName_error: true });
    // }
  }
  handleSelectDevice = (e, data) => {
    const { key } = data.options.find(o => o.value === data.value);
    const { text } = data.options.find(o => o.value === data.value);
    console.log("Key", key);
    console.log("value", data.value);
    console.log("text", text);

    this.setState({
      // boatName: data.value,
      device_id: key,
      device_eid: text
    });

    this.props.sendData(key, text);
  };
  render() {
    const { values } = this.props;
    return (
      <div>
        <Form.Field>
          <label>Select a Device No:</label>
          <Dropdown
            placeholder="Device Number"
            search
            selection
            options={this.state.deviceList}
            className="form-input"
            //name={this.props.deviceNo}
            text={this.props.values.deviceList.text}
            defaultValue={values.device_id}
            onChange={this.handleSelectDevice}
            //onSearchChange={this.onSearchChange}
          />
          {this.state.device_eid_error ? (
            <Label
              //  basic
              color="red"
              pointing
            >
              {this.state.device_eid_error_message}
            </Label>
          ) : null}
          <label>Boat Name</label>
          <input
            placeholder="Boat Name"
            className="form-input"
            name={this.props.boatName}
            defaultValue={values.boatName}
            //  onChange={this.props.boatNameFunc}
            onBlur={this.handlerBoatNameValidator}
          />

          {/* {this.state.boatName_error ? (
            <Label
              //  basic
              color="red"
              pointing
            >
              {this.state.boatName_error_message}
            </Label>
          ) : null} */}
        </Form.Field>
      </div>
    );
  }
}

DeviceDetails.propTypes = {
  deviceNoFunc: PropTypes.func.isRequired,
  boatNameFunc: PropTypes.func.isRequired,
  boatName: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired
};

export default DeviceDetails;
