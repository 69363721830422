import React, { Component } from "react";
import { Button, Icon, Image, Menu, Segment, Sidebar } from "semantic-ui-react";
import ChangePassword from "./ChangePassword";
import AdminLandingSegment from "./Admin/AdminLandingSegment";
import OnBoarding from "./Admin/OnBoarding/OnBoarding";
import HomePage from "../HomePage";
import UserList from "./Admin/Users/UserList";
import FleetList from "./Admin/Fleets/FleetList";
import DeviceList from "./Admin/Devices/DeviceList";
import { Auth } from "aws-amplify";
import { API } from "aws-amplify";
import config from "../../config";
import AWS from "aws-sdk";
import awsConfig from "../../commons/aws_config.json";
import { log } from "util";

const uuid = require("uuid/v4");
class HomeLayout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      view: "HomePage",
      isAuthenticated: false,
      isAuthenticating: true,
      singleAsset: {
        key: "",
        text: "",
        value: ""
      },
      assetList: [],
      assetDropDownList: [],
      singleDevice: {
        key: "",
        text: "",
        value: ""
      },
      deviceList: [],
      asset_device_list: [],
      assets_list_for_device: [],
      deviceDropDownList: [],
      singleUser: {
        key: "",
        text: "",
        value: ""
      },
      userList: [],
      newCognitoUser: [],
      password: "BoatSecure@1",
      new_user_sub: "",
      username_LIst: [],
      email_list: [],
      asset_user_list: []
    };
    this.selectViewhandler = this.selectViewhandler.bind(this);
    this.addUserDBHandler = this.addUserDBHandler.bind(this);
    this.addUserHandler = this.addUserHandler.bind(this);
    this.getAllAssets = this.getAllAssets.bind(this);
    this.getAllDevices = this.getAllDevices.bind(this);
    this.getAllAsset_Users = this.getAllAsset_Users.bind(this);
    this.getMappedDevices = this.getMappedDevices.bind(this);
    this.getMappedAsset = this.getMappedAsset.bind(this);
    this.addUserDBHandler = this.addUserDBHandler.bind(this);
    this.updateDeviceHandler = this.updateDeviceHandler.bind(this);
    this.updateFleetHandler = this.updateFleetHandler.bind(this);
    this.addDeviceHandler = this.addDeviceHandler.bind(this);
    this.updateUserHandler = this.updateUserHandler.bind(this);
    this.onBoardingProcess = this.onBoardingProcess.bind(this);
    this.update_asset_user_list_handler = this.update_asset_user_list_handler.bind(
      this
    );
    this.getMappedAsset_User = this.getMappedAsset_User.bind(this);
  }
  async componentDidMount() {
    let device_list = [];
    let asset_list = [];
    let user_list = [];
    let asset_user_list = [];
    let asset_device_list = [];
    try {
      asset_list = await this.getAllAssets();
      device_list = await this.getAllDevices();
      user_list = await this.getAllUsers();
      asset_user_list = await this.getAllAsset_Users();
      } catch (e) { }
      //asset_device_list = await this.getAllAsset_Devices();
      this.setState({
          asset_device_list: asset_device_list
      });
      console.log('asset_list', asset_list)
      if (asset_list.data.length > 0) {
          var assets_list_for_device = []
          for (let i = 0; i < asset_list.data.length; i++) {
              assets_list_for_device.push(
                  { key: asset_list.data[i].id, text: asset_list.data[i].attributes.name, value: asset_list.data[i].id })
        
          }

          this.setState({
              assets_list_for_device: assets_list_for_device
          });
      }
     
      console.log('asset_device_list', asset_device_list)
    this.getMappedDevices(device_list);
    this.getMappedAsset(asset_list);
    this.getMappedUser(user_list);
    this.getMappedAsset_User(asset_user_list);
  }
  getAllUsers() {
    const path = `/users/get_all_users`;
    return API.get("boatsecure", path);
  }
  getAllAssets() {
    const path = `/assets/get_all_assets`;
    return API.get("boatsecure", path);
  }

  getAllDevices() {
    const path = `/devices/get_all_devices`;
    return API.get("boatsecure", path);
  }

  getAllAsset_Users() {
    const path = `/assets/get_all_assets_users`;
    return API.get("boatsecure", path);
    }

    getAllAsset_Devices() {
        console.log("2222222222222222222222");
        const path = `/assets/get_all_asset_devices`;
        return API.get("boatsecure", path);
    }

  getMappedUser(user_list) {
    this.setState({
      userList: user_list
    });
  }

  getMappedAsset_User(asset_user_list) {
    this.setState({
      asset_user_list: asset_user_list
    });
    console.log("getMappedAsset_User", this.state.asset_user_list);
  }

  getMappedAsset(asset_list) {
    let assetList = [];
    for (let i = 0; i < asset_list.data.length; i++) {
      this.setState({
        singleAsset: {
          key: asset_list.data[i].id,
          text: asset_list.data[i].attributes.name,
          value: asset_list.data[i].attributes.asset_eid
        }
      });
      assetList.push(this.state.singleAsset);
    }
    assetList = this.commonSortingHandler(assetList, "text");
   this.setState({
      assetDropDownList: assetList,
      assetList: asset_list
    });
  }

  getMappedDevices(device_list) {
    let deviceList = [];
    for (let i = 0; i < device_list.data.length; i++) {
      let text = device_list.data[i].attributes.device_eid;
      let key = device_list.data[i].id;
      let value = device_list.data[i].id;
      let deviceSingle = {
        key,
        text,
        value
      };
      deviceList.push(deviceSingle);
    }

    deviceList = this.commonSortingHandler(deviceList, "text");
    this.setState({
      deviceDropDownList: deviceList,
      deviceList: device_list
    });
  }

  commonSortingHandler(list, key) {
    function compare(a, b) {
      a = a[key];
      b = b[key];
      var type =
        typeof a === "string" || typeof b === "string" ? "string" : "number";
      var result;
      if (type === "string") result = a.localeCompare(b);
      else result = a - b;
      return result;
    }
    return list.sort(compare);
  }

  handleSideBarClick = () => this.setState({ visible: !this.state.visible });
  handleSidebarHide = () => this.setState({ visible: false });
  handleDrawer = () => {
    this.refs.customDrawerReference.handleToggle();
  };
  handleLogout = event => {
    this.props.logOutHandler();
  };

  selectViewhandler = selectedView => {
    this.setState({ step: selectedView });

    switch (selectedView) {
      case 1:
        this.setState({
          view: "AdminLandingSegment",
          visible: false
        });
        break;
      case 2:
        this.setState({ view: "Userlist" });
        break;
      case 3:
        this.setState({ view: "Fleetlist" });
        break;
      case 4:
        this.setState({ view: "Devicelist" });
        break;
      case 5:
        this.setState({ view: "OnBoarding" });
        break;
      case 6:
        this.setState({
          view: "HomePage",
          visible: false
        });
        break;
      default:
        this.setState({ view: "HomePage" });
    }
  };

  async onBoardingProcess(e) {
    console.log("onboarding details", e);
    let addFleetResponse = [];
    let addAsset_UserResponse = [];
    let updateDeviceResponse = [];
    let addUserResponse = [];
    let addUserResponseTEST = "";
    

    try {
      addUserResponseTEST = await this.addUserHandler(e);
    } catch (e) {}

    //   try {
    ///////////////////////////  addUserResponse = await this.addUserHandler(e);
    // if (e.asset_id !== "notNow"){
    //   addFleetResponse = await  this.addFleetHandler(e, addUserResponse.sub);
    // }
    //     addFleetResponse = await  this.addFleetHandler(e, addUserResponse.sub);
    //     addAsset_UserResponse = await this.addAsset_UserHandler(addFleetResponse.asset_user_info);
    //    updateDeviceResponse= await  this.updateDeviceHandler(e, addFleetResponse.id);
    //   } catch (e) {}

    //  // addFleetResponse = await addUserResponse.

    console.log("====================================");
    console.log("addUserResponse", addUserResponseTEST);
   // addUserResponseTEST.then(x => console.log("addUserResponseTEST",x)); // Hello Alligator!
    console.log("====================================");
    // if (addUserResponse.result === true){
    //     addFleetResponse = await  this.addFleetHandler(e, addUserResponse.sub);
    //     console.log('====================================');
    // console.log("addFleetResponse",addFleetResponse);
    // console.log('====================================');
    // }
    // if (addFleetResponse.result === true){
    //    addAsset_UserResponse = await this.addAsset_UserHandler(addFleetResponse.asset_user_info);
    //    updateDeviceResponse= await  this.updateDeviceHandler(e, addFleetResponse.id);
    //    console.log('====================================');
    //    console.log("addAsset_UserResponse",addAsset_UserResponse);
    //    console.log("updateDeviceResponse",updateDeviceResponse);
    //    console.log('====================================');
    // }

    // return  (this.addUserHandler(e), {
    // })
    //   .then(() => {
    //     console.log("then sub", signup_info);
    //    // this.addFleetHandler(e, sub);
    //     return sub ;
    //   })
    //   .catch(err => {
    //     console.log(" this is the error", err);
    //     result = false
    //     return result;
    //   });
  }

  async addUserHandler(e) {
    console.log(" the user details from onboarding ", e);
    const username = e.username;
    const password = e.password;
    const email = e.email;
    const given_name = e.firstName;
    const family_name = e.lastName;
    let phone_number = e.phone.replace("+64", "");
    phone_number = "+64" + phone_number;
    const phone = phone_number == "" ? "+6400000" : phone_number;
    console.log("phone",phone);
    // let newCognitoUser = "";
    // let signup_info = {
    //   username,
    //   password,
    //   attributes: {
    //     email
    //   }
    // };
    // Auth.signUp(signup_info)
    //   .then(newUser => {
    //     this.addUserDBHandler(e, newUser);
    //   })
    //   .catch(err => {
    //     console.log(" this is the error", err);
    //   });

    AWS.config.update(awsConfig);
    var cognitoidentityserviceprovider = new AWS.CognitoIdentityServiceProvider();

    var params = {
      UserPoolId: config.cognito.USER_POOL_ID, // 'STRING_VALUE', /* required */
      Username: username, //'STRING_VALUE', /* required */
      //Region: config.cognito.REGION,
      DesiredDeliveryMediums: [
        "EMAIL"
        //SMS | EMAIL,
        /* more items */
      ],
      ForceAliasCreation: false, // true || false,
      //MessageAction: "SUPPRESS",     //RESEND | SUPPRESS,
      TemporaryPassword: password, //'STRING_VALUE',
      UserAttributes: [
        {
          Name: "email" /* required */,
          Value: email //'STRING_VALUE'
        },
        {
          Name: "given_name" /* required */,
          Value: given_name //'STRING_VALUE'
        },
        {
          Name: "family_name" /* required */,
          Value: family_name //'STRING_VALUE'
        },
        {
          Name: "phone_number" /* required */,
          Value: phone //'STRING_VALUE'
          },
        {
              Name: 'email_verified', /* required */
              Value: 'true'
        }

        /* more items */
      ]
      // ValidationData: [
      //   {
      //     Name: 'STRING_VALUE', /* required */
      //     Value: 'STRING_VALUE'
      //   },
      //   /* more items */
      // ]
    };
    console.log("params", params);
    await cognitoidentityserviceprovider.adminCreateUser(
      params,
      function(err, newUser) {
        if (err) {
          console.log(err, err.stack);
        } else {
         // console.log("newUser One", newUser); // successful response
          this.setState({ newCognitoUser: newUser });
          this.addUserDBHandler(e, this.state.newCognitoUser);
        }
      }.bind(this)
    );
  }

  //addUserDBHandler = (e, userdata) => {
  async addUserDBHandler(e, userdata) {
  //  console.log("addUserDBHandler e", e);
    let sub = "",
      given_name = "",
      family_name = "",
      email = "",
      phone_number = "",
      asset_id = "";
    const callFrom = e.callFrom;
    let result = false;
    for (var i = 0; i < userdata.User.Attributes.length; i++) {
      switch (userdata.User.Attributes[i].Name) {
        case "sub":
          sub = userdata.User.Attributes[i].Value;
          break;
        case "given_name":
          given_name = userdata.User.Attributes[i].Value;
          break;
        case "family_name":
          family_name = userdata.User.Attributes[i].Value;
          break;
        case "email":
          email = userdata.User.Attributes[i].Value;
          break;
        case "phone_number":
          phone_number = userdata.User.Attributes[i].Value;
          break;
      }
    }
    let username = userdata.User.Username;
    let id = sub;
    let user_data = {
      sub,
      given_name,
      family_name,
      email,
      phone_number,
      username,
      id
    };
    let session_id = "";
    let signup_info = {
      user_data,
      session_id
    };
    let dbResult = false;
    const path = `/users`;
    //  console.log("this.state.userList pre add/edit user", this.state.userList);
    let index = 0;

    for (let i = 0; i < this.state.userList.data.length; i++) {
      if (this.state.userList.data[i].id == sub) {
        index = i;
        break;
      }
    }
    this.state.userList.data = this.state.userList.data.filter(data => {
      if (data.id !== sub) return data;
    });
    //return API.post("boatsecure", path, {
    dbResult = await API.post("boatsecure", path, {
      body: signup_info
    })
      .then(response => {
        console.log("then sub", signup_info);
        //   console.log("response after add user", response.data);
        //  this.state.userList.data.unshift(response.data);
        this.state.userList.data.splice(index, 0, response.data);
        this.setState({ userList: this.state.userList });
        //    console.log("this.state.userList post add user", this.state.userList);
        result = true;
        // console.log("result", result);

        switch (callFrom) {
          case "OnBoarding":
            {
              if (e.newFleet == true) {
                this.addFleetHandler(e, sub);
              } else {
                asset_id = e.fleetNo;
                let user_id = sub;
                let asset_eid = e.fleetNo;
                let asset_user_info = {
                  user_id,
                  asset_eid
                };
                this.addAsset_UserHandler(asset_user_info);
                if (e.newBoat == true) {
                  this.updateDeviceHandler(e, asset_eid);
                }
              }
            }

            break;
          case "AddUser":
            {
              console.log("addUserdb AddUser");
              if (e.addToFleet == true) {
                let asset_user_List = [];
                for (let i = 0; i < e.user_asset_list.length; i++) {
                  asset_user_List.push([e.user_asset_list[i], sub]);
                }
                this.insertAsset_UserLIst_handler(asset_user_List);
              }
            }

            break;
          default:
        }
      })
      .catch(err => {
        console.log(" this is the error", err);
        result = false;
        //  console.log("result", result);
        //   return result;
        // return false;
      });
     console.log("dbResult", dbResult);
    //return dbResult;




    // return new Promise(resolve => {
    //   setTimeout(() => {
    //     resolve('lurks');
    //   }, 300);






      
    // });









  }

  async insertAsset_UserLIst_handler(asset_user_list) {
    console.log(
      " Add asset_user   insertAsset_UserLIst_handler",
      asset_user_list
    );
    let asset_user_list_insert = asset_user_list;
    let insert_asset_user_list = {
      asset_user_list_insert
    };

    // let asset_user_info = {
    //   user_id,
    //   asset_eid
    // };

    for (let i = 0; i < asset_user_list_insert.length; i++) {
      let asset_eid = asset_user_list_insert[i][0];
      let user_id = asset_user_list_insert[i][1];
      let asset_user_info = {
        user_id,
        asset_eid
      };
      await this.addAsset_UserHandler(asset_user_info);
    }

    //============ FOR MULTIPLE INSERTION======

    // const path = `/assets/create_asset_user_list`;
    // let asset_user_list_insert = asset_user_list;
    // let insert_asset_user_list = {
    //   asset_user_list_insert
    // };
    // return API.post("boatsecure", path, {
    //   body: insert_asset_user_list
    // })
    //   .then(() => {
    //     console.log("then deleted", insert_asset_user_list);
    //   })
    //   .catch(err => {
    //     console.log(" this is the error", err);
    //   });

    //============ FOR MULTIPLE INSERTION CLOSE======
  }

  async updateUserHandler(e) {
    console.log("homelayout updateUserHandler", e);
    const username = e.username;
    const password = e.password;
    const email = e.email;
    const given_name = e.firstname;
    const family_name = e.lastname;
    let phone_number = e.phone.replace("+64", "");
    
    let phone = phone_number == "" ? "00000" : phone_number;
    phone = "+64" + phone;
    const sub = e.id;
    let asset_user_List = [];
    AWS.config.update(awsConfig);
    var cognitoidentityserviceprovider = new AWS.CognitoIdentityServiceProvider();
    console.log("phone", phone);
    var params = {
      UserAttributes: [
        /* required */
        {
          Name: "given_name" /* required */,
          Value: given_name //'STRING_VALUE'
        },
        {
          Name: "family_name" /* required */,
          Value: family_name //'STRING_VALUE'
        },
        {
          Name: "phone_number" /* required */,
          Value: phone //'STRING_VALUE'
          },
        {
              Name: 'email_verified', /* required */
              Value: 'true'
          }

      ],
      UserPoolId: config.cognito.USER_POOL_ID, // 'STRING_VALUE', /* required */
      Username: username //'STRING_VALUE', /* required */
    };

    let updatedUser = {
      User: {
        Attributes: [
          /* required */
          {
            Name: "sub" /* required */,
            Value: sub //'STRING_VALUE'
          },
          {
            Name: "given_name" /* required */,
            Value: given_name //'STRING_VALUE'
          },
          {
            Name: "family_name" /* required */,
            Value: family_name //'STRING_VALUE'
          },
          {
            Name: "phone_number" /* required */,
            Value: phone //'STRING_VALUE'
          },
          {
            Name: "email" /* required */,
            Value: email //'STRING_VALUE'
          }
        ],

        Username: username
      }
    };

    await cognitoidentityserviceprovider.adminUpdateUserAttributes(
      params,
      function(err, data) {
        if (err) console.log(err, err.stack);
        else {
          console.log("updated User One", data);
          console.log("updated User One params", params); // successful response
          this.addUserDBHandler(e, updatedUser);
        }
      }.bind(this)
    );

    //  this.addUserDBHandler(e, updatedUser);
    if (e.addOrRemoveFleet == true) {
      for (let i = 0; i < e.user_asset_list.length; i++) {
        asset_user_List.push([e.user_asset_list[i], sub]);
      }
      // var params = []
      // var chunks = []
      // for(var i = 0; i < asset_user_List.length; i++) {
      //   console.log('====================================');
      //   console.log("asset_user_List[i]",asset_user_List[i]);
      //   console.log('====================================');
      //   var row = asset_user_List[i]
      //   var valueClause = []
      //   params.push(row)
      //   valueClause.push( params)
      //   chunks.push('(' + valueClause.join(', ') + ')')
      // }

      //===========FOR MULTIPLE INSERTION TESTING===========
      // const params = [];
      // const chunks = [];
      // asset_user_List.forEach(asset_user_Lis => {
      //   const valueClause = [];
      //   Object.keys(asset_user_Lis).forEach(p => {
      //     params.push(asset_user_Lis[p]);
      //     valueClause.push("$" + params.length);
      //   });
      //   chunks.push("(" + valueClause.join(', ') + ")");
      //   chunks.join(', ');
      // });
      // console.log("====================================");
      // console.log("CHUNKS", chunks);
      // console.log("====================================");
      // console.log("====================================");
      // console.log("PARAMS", params);
      // console.log("====================================");

      //===========FOR MULTIPLE INSERTION TESTING   CLOSE===========

      await this.delete_user_asset_list_handler(sub);
      await this.insertAsset_UserLIst_handler(asset_user_List);
      await this.update_asset_user_list_handler();
    }
  }

  async update_asset_user_list_handler() {
    // console.log("update_asset_user_list_handler   state", this.state.asset_user_list);
    let asset_user_list = [];
    try {
      asset_user_list = await this.getAllAsset_Users();
    } catch (e) {}
    // console.log("update_asset_user_list_handler asset_user_list ",asset_user_list)
    this.getMappedAsset_User(asset_user_list);
  }

  delete_user_asset_list_handler(sub) {
    // console.log("delete_user_asset_list_handler sub", sub);
    let user_id = sub;
    let delete_asset_user = {
      user_id
    };
    const path = `/assets/delete_asset_user_list`;
    return API.del("boatsecure", path, {
      body: delete_asset_user
    })
      .then(() => {
        //  console.log("then deleted", delete_asset_user);
      })
      .catch(err => {
        //  console.log(" this is the error", err);
      });
  }

  addFleetHandler = (e, user_sub) => {
    // console.log(" Add Fleet user details", user_sub);
    // console.log(" Add Fleet details", e);
    // console.log("assetList", this.state.assetList);
    let result = true;
    const user_id = user_sub;
    const name = e.fleetName;
    const first_contact = e.fleetManagerName;
    const asset_eid = uuid();
    const id = asset_eid;
    const app_id = config.appDetails.APP_ID;
    const location = e.location;
    const callFrom = e.callFrom;
    // console.log("callFrom", callFrom);
    const path = `/assets/create-asset`;
    let asset_info = {
      id,
      asset_eid,
      user_id,
      name,
      first_contact,
      app_id,
      location
    };
    let asset_user_info = {
      user_id,
      asset_eid
    };
    // console.log("asset_info", asset_info);
    // console.log("asset_user_info", asset_user_info);
    return API.post("boatsecure", path, {
      body: asset_info
    })
      .then(response => {
        //console.log("response after adding a fleet", response)
       
        this.state.assetList.data.unshift(response.data);
        this.setState({ assetList: this.state.assetList });
        // if(e.callFrom =="OnBoarding"){
        //   this.addAsset_UserHandler(asset_user_info);
        //                                                     ///////////////////////////////////////////////
        //   if (e.device_id != "nowNow") {
        //     this.updateDeviceHandler(e, id);
        //   }
        // }

        // if(e.callFrom =="AddUser"){
        //   this.addAsset_UserHandler(asset_user_info);
        //                                                     ///////////////////////////////////////////////
        //   if (e.device_id != "nowNow") {
        //     this.updateDeviceHandler(e, id);
        //   }
        // }
        switch (callFrom) {
          case "OnBoarding":
            {
              this.addAsset_UserHandler(asset_user_info);
              //TODO have to update the 
              this.update_asset_user_list_handler();
              if (e.newBoat == true) {
                this.updateDeviceHandler(e, id);
              }
            }

            break;
          case "AddFleet":
            {
            }

            break;
          default:
        }
      })
      .catch(err => {
        //console.log(" this is the asset error", err);
        result = false;
        return result;
      });
  };

  addAsset_UserHandler(asset_user_info) {
    // console.log(" Add asset_user", asset_user_info);
    const path = `/assets/create-asset-user`;
    return API.post("boatsecure", path, {
      body: asset_user_info
    })
      .then(() => {
        // set the flag to loadingFlag to false
        // console.log("addAsset_UserHandler success");
      })
      .catch(err => {
        //   console.log(" this is the asset_user_info error", err);
      });
  }

  updateDeviceHandler = (e, asset_id) => {
   console.log("update Device in homelayout", e);
    const device_id = e.device_id;
    const device_eid = e.device_eid;
    const user_fleet_list = e.user_fleet_list;
    const name = e.boatName;
    let device_info = {
        device_eid,
        device_id,
      user_fleet_list,
      name
    };
    const path = `/devices`;
    let index = 0; //this.state.deviceList.data.id.indexOf(device_id);
    for (let i = 0; i < this.state.deviceList.data.length; i++) {
      if (this.state.deviceList.data[i].id == device_id) {
        index = i;
        break;
      }
    }
    //let index  = this.state.deviceList.data.indexOf(data => {
    //    (data.id == device_id)
    // });
    // console.log("index device", index)
    this.state.deviceList.data = this.state.deviceList.data.filter(data => {
      if (data.id !== device_id) return data;
    });

    return API.put("boatsecure", path, {
      body: device_info
    })
      .then(response => {
        // set the flag to loadingFlag to false
        this.state.deviceList.data.splice(index, 0, response.data);
        this.setState({ deviceList: this.state.deviceList });
      })
      .catch(err => {
        // console.log(" this is the device error", err);
      });
  };

  updateFleetHandler = e => {
    //  console.log("homelayout updateDeviceHandler", e);
    const app_id = config.appDetails.APP_ID;
    const asset_eid = e.asset_eid;
    const id = e.asset_id;
    const first_contact = e.fleetManagerName;
    const name = e.fleetName;
    const location = e.location;
    let asset_info = {
      app_id,
      asset_eid,
      id,
      first_contact,
      name,
      location
    };
    let index = 0;
    for (let i = 0; i < this.state.assetList.data.length; i++) {
      if (this.state.assetList.data[i].id == id) {
        index = i;
        break;
      }
    }
    this.state.assetList.data = this.state.assetList.data.filter(data => {
      if (data.id !== id) return data;
    });

    const path = `/assets/update_asset`;
    return API.put("boatsecure", path, {
      body: asset_info
    })
      .then(response => {
        // set the flag to loadingFlag to false
        //   console.log("the response after fleet editing", response);
        //this.state.assetList.data.push(response.data);
        this.state.assetList.data.splice(index, 0, response.data);
        this.setState({ assetList: this.state.assetList });
      })
      .catch(err => {
        //   console.log(" this is the update asset error", err);
      });
  };

  addDeviceHandler = e => {
    // console.log("addDeviceHandler homelayout", e);
    const device_id = uuid();
    const device_eid = e.device_eid;
    const name = e.device_name;
    const asset_id = e.asset_id;
    let device_info = {
      device_id,
      device_eid,
      asset_id,
      name
    };
    const path = `/devices/create_new_device`;
    return API.post("boatsecure", path, {
      body: device_info
    })
      .then(() => {
        // set the flag to loadingFlag to false
      })
      .catch(err => {
        //  console.log(" this is the  create device error", err);
      });
  };

  deleteDeviceHandler = e => {
    //  console.log("deleteDeviceHandler", e);
    const id = e.deleteItemId;
    let device_info = {
      id
    };
    const path = `/devices/delete_device`;
    this.state.deviceList.data = this.state.deviceList.data.filter(data => {
      if (data.id !== id) return data;
    });

    return API.put("boatsecure", path, {
      body: device_info
    })
      .then(response => {
        // set the flag to loadingFlag to false
        //     console.log("response after del device", response.data);
        this.setState({ deviceList: this.state.deviceList });
      })
      .catch(err => {
        // console.log(" this is the device error", err);
      });
  };

  deleteUserHandler = e => {
    //   console.log("deleteUserHandler", e);
    // console.log("User handler id", e.deleteItemId);
    // console.log("User handler name", e.deleteItemName);
    const username = e.deleteItemName;
    const cognitoInActive = e.additionalMessageEnabled;
    const sub = e.deleteItemId;
    let user_data = {
      sub
    };
    let delete_user_info = {
      user_data
    };
    if (!cognitoInActive) {
      //    console.log("Disable user in Cognito");
      AWS.config.update(awsConfig);
      var cognitoidentityserviceprovider = new AWS.CognitoIdentityServiceProvider();
      var params = {
        UserPoolId: config.cognito.USER_POOL_ID, // 'STRING_VALUE', /* required */
        Username: username //'STRING_VALUE', /* required */
      };
      //     console.log("params", params);
      cognitoidentityserviceprovider.adminDisableUser(params, function(
        err,
        data
      ) {
        if (err) console.log(err, err.stack);
        // an error occurred
        else console.log("no erre", data); // successful response
      });
    }
    const path = `/users/delete_user`;
    this.state.userList.data = this.state.userList.data.filter(data => {
      if (data.id !== sub) return data;
    });
    return API.put("boatsecure", path, {
      body: delete_user_info
    })
      .then(response => {
        //       console.log("response after del user", response.data);
        this.setState({ userList: this.state.userList });
        // set the flag to loadingFlag to false
      })
      .catch(err => {
        //       console.log(" this is the device error", err);
      });
  };

  deleteFleetHandler = e => {
    // console.log("assetList", this.state.assetList);
    const app_id = config.appDetails.APP_ID;
    const id = e.deleteItemId;
    let asset_info = {
      app_id,
      id
    };

    // const postDeletionAssetList =[];
    // for (let i = 0; i < this.state.assetList.data.length; i++) {
    //   if(this.state.assetList.data[i].id !== id){
    //     postDeletionAssetList.push(this.state.assetList.data[i])
    //   }

    // }

    this.state.assetList.data = this.state.assetList.data.filter(data => {
      if (data.id !== id) return data;
    });
    const path = `/assets/delete_asset`;
    return API.put("boatsecure", path, {
      body: asset_info
    })
      .then(() => {
        // set the flag to loadingFlag to false
        //  console.log("response after deleting a fleet",postDeletionAssetList )
        this.setState({ assetList: this.state.assetList });
      })
      .catch(err => {
        //    console.log(" this is the update asset error", err);
      });
  };
  render() {
    const {
      visible,
      assetList,
      assetDropDownList,
      deviceList,
      deviceDropDownList,
      userList,
      password,
        asset_user_list,
        asset_device_list,
        assets_list_for_device
    } = this.state;
    const values = {
      visible,
      assetList,
      assetDropDownList,
      deviceList,
      deviceDropDownList,
      userList,
      password,
        asset_user_list,
        asset_device_list,
        assets_list_for_device
    };
    const menustyle = { border: "1px  solid #2185d0" };
    const childProps = {
      isAuthenticated: this.state.isAuthenticated,
      userHasAuthenticated: this.userHasAuthenticated
    };
    const SegmentStyle = { height: "100%" };
    return (
      <div
        className="ui bottom attached segment pushable"
        style={{ minHeight: "100%" }}
      >
        <Sidebar.Pushable as={Segment}>
          <Sidebar
            as={Menu}
            animation="overlay"
            icon="labeled"
            onHide={this.handleSidebarHide}
            vertical
            visible={visible}
            width="thin"
          >
            <Menu.Item as="a">
              <Image src="/boatsecurelogo.png" />
            </Menu.Item>

            <Menu.Item>
              <Icon name="user" />
              Current User
            </Menu.Item>

            <Menu.Item as="a" onClick={() => this.selectViewhandler(6)}>
              <Icon name="home" />
              Home
            </Menu.Item>
            <Menu.Item as="a" onClick={() => this.selectViewhandler(1)}>
              <Icon name="users" />
              Admin
            </Menu.Item>

            <Menu.Item as={ChangePassword} />

            <Menu.Item as="a" onClick={() => this.handleLogout()}>
              <Icon name="log out" />
              Log Out
            </Menu.Item>
          </Sidebar>

          {/* The header section */}
          <Menu className="header-menu" style={menustyle} stackable>
            <Menu.Item>
              <img src="/boatsecure-header-logo.png" alt="" />
            </Menu.Item>

            <Menu.Item>
              <Button basic color="blue" onClick={this.handleSideBarClick}>
                BoatSecure
              </Button>
            </Menu.Item>
          </Menu>

          <Sidebar.Pusher dimmed={visible}>
            <div className="main-div">
              <style>{`div.main-div { width: 90%; height:50%; margin:0 auto; } `}</style>
              {(() => {
                switch (this.state.view) {
                  case "AdminLandingSegment":
                    return (
                      <AdminLandingSegment callBack={this.selectViewhandler} />
                    );
                  case "Userlist":
                    return (
                      <UserList
                        callBack={this.selectViewhandler}
                        values={values}
                        callBackAddUser={this.addUserHandler}
                        callBackEditUser={this.updateUserHandler}
                        callBackDeleteUser={this.deleteUserHandler}
                      />
                    );
                  case "Fleetlist":
                    return (
                      <FleetList
                        callBack={this.selectViewhandler}
                        values={values}
                        callBackAddFleet={this.addFleetHandler}
                        callBackEditFleet={this.updateFleetHandler}
                        callBackDeleteFleet={this.deleteFleetHandler}
                      />
                    );
                  case "Devicelist":
                    return (
                      <DeviceList
                        callBack={this.selectViewhandler}
                        values={values}
                        callBackEditDevice={this.updateDeviceHandler}
                        callBackAddDevice={this.addDeviceHandler}
                        callBackDeleteDevice={this.deleteDeviceHandler}
                      />
                    );
                  case "OnBoarding":
                    return (
                      <OnBoarding
                        callBack={this.selectViewhandler}
                        callBackAddUser={this.onBoardingProcess}
                        callBackAddFleet={this.addFleetHandler}
                        callBackAddDevice={this.updateDeviceHandler}
                        values={values}
                      />
                    );
                  case "HomePage":
                    return <HomePage />;
                }
              })()}
            </div>
          </Sidebar.Pusher>
        </Sidebar.Pushable>
      </div>
    );
  }
}

export default HomeLayout;
