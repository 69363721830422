import React from "react";
import {
  Button,
  Header,
  Form,
  Image,
  Icon,
  Modal,
  Message
} from "semantic-ui-react";
import FleetDetails from "./FleetDetails";

export default class AddFleet extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      addFleetModelOpen: false,
      fleetName: "",
      fleetNo: "",
      fleetManagerName: "",
      location: "",
      fleetName_validated: false,
      fleetManagerName_validated: false,
      location_validated: false,
      fleetName_error: false,
      fleetManagerName_error: false,
      location_error: false,
      fleetName_error_message: "Please enter a valid fleet name",
      formValidationError: false,
      fleetManagerName_error_message: "Please enter a valid name",
      location_error_message: "Please enter a valid location",
      callFrom : "AddFleet",
    };
    this.handleFleetNameChange = this.handleFleetNameChange.bind(this);
    this.handleFleetNoChange = this.handleFleetNoChange.bind(this);
    this.handleFleetManagerNameChange = this.handleFleetManagerNameChange.bind(
      this
    );
    this.handleLocationChange = this.handleLocationChange.bind(this);
    this.validateForm = this.validateForm.bind(this);
    this.handleAddFleet = this.handleAddFleet.bind(this);
  }

  handleFleetNameChange(e) {
   // console.log("fleetName FnBboardi");
    this.setState({
      fleetName: e,
      fleetName_validated: true,
      fleetName_error: false
    });
    this.validateForm();
  }

  handleLocationChange(e) {
    this.setState({
      location: e.target.value,
      location_validated: true,
      location_error: false
    });
    this.validateForm();
  }

  handleFleetNoChange(e) {
   // console.log("fleetNo FnBboardi");
    this.setState({
      fleetNo: e.target.value
    });
  }

  handleFleetManagerNameChange(e) {
  //  console.log("fleetManagerName FnBboardi");
    this.setState({
      fleetManagerName: e.target.value,
      fleetManagerName_validated: true,
      fleetManagerName_error: false
    });
    this.validateForm();
  }
  async handleAddFleet(e) {
    e.preventDefault();
    console.log("add fleet  ");

    await this.validateForm();
    console.log("form validator", this.state.formValidationError);
    if (!this.state.formValidationError) {
      console.log(this.state);
      this.setState({ addFleetModelOpen: false });
      this.props.callBackAddFleet(this.state);
    }
    // async and await them relinquish the states
    // compnentdid um ount()
  }

  handleOpenAddFleet = () => {
    this.setState({
      addFleetModelOpen: true,
      fleetName: "",
      fleetNo: "",
      fleetManagerName: "",
      location: "",
    });
  };

  handleCloseAddFleet = () => {
    this.setState({
      addFleetModelOpen: false
    });
  };

  validateForm() {
    console.log("fleetName_validated", this.state.fleetName_validated);
    // console.log(
    //   "fleetManagerName_validated",
    //   this.state.fleetManagerName_validated
    // );
    // console.log("location_validated", this.state.location_validated);
    if (this.state.fleetName == "") {
      this.setState({
        fleetName_validated: false,
        fleetName_error: true,
        formValidationError: true
      });
      console.log("fleetName form validator", this.state.formValidationError);
    } else {
      this.setState({
        fleetName_validated: true,
        fleetName_error: false,
        formValidationError: false
      });
      console.log("fleetName form validator", this.state.formValidationError);
    }
    // if (this.state.fleetManagerName == "") {
    //   this.setState({
    //     fleetManagerName_validated: false,
    //     fleetManagerName_error: true,
    //     formValidationError: true
    //   });
    //   console.log(
    //     "fleetManagerName form valudator",
    //     this.state.formValidationError
    //   );
    // } else {
    //   this.setState({
    //     fleetManagerName_validated: true,
    //     fleetManagerName_error: false,
    //     formValidationError: false
    //   });
    //   console.log(
    //     "fleetManagerName form valudator",
    //     this.state.formValidationError
    //   );
    // }
    // if (this.state.location == "") {
    //   this.setState({
    //     location_validated: false,
    //     location_error: true,
    //     formValidationError: true
    //   });
    //   console.log("location form valudator", this.state.formValidationError);
    // } else {
    //   this.setState({
    //     location_validated: true,
    //     location_error: false,
    //     formValidationError: false
    //   });
    //   console.log("location form valudator", this.state.formValidationError);
    // }
  }
 
  render() {
    const {
      fleetManagerName,
      fleetName,
      fleetNo,
      location,
      addFleetModelOpen
    } = this.state;
    const values = {
      fleetManagerName,
      fleetName,
      fleetNo,
      location,
      addFleetModelOpen
    };
    return (
      <Modal
        size={"tiny"}
        dimmer={"blurring"}
        trigger={
          <Button basic color="blue" onClick={this.handleOpenAddFleet}>
            <Icon name="add" />
            Add a new Fleet
          </Button>
        }
        centered={false}
        open={addFleetModelOpen}
        onClose={this.handleCloseAddFleet}
        closeIcon
      >
        <Header>
          <Image floated="left" size="mini" src="/fleet.svg" />
          Enter the Fleet Details
        </Header>
        <Modal.Content>
          <Form>
            <FleetDetails
              fleetNameFunc={this.handleFleetNameChange}
              fleetName={this.state.fleetName}
              locationFunc={this.handleLocationChange}
              location={this.state.location}
              fleetManagerNameFunc={this.handleFleetManagerNameChange}
              fleetManagerName={this.state.fleetManagerName}
              values={values}
            />
          </Form>

          {this.state.formValidationError ? (
            <Message negative>
              <Message.Header>Please check the following fields</Message.Header>
              {this.state.fleetName_error ? (
                <p>{this.state.fleetName_error_message}</p>
              ) : null}
              {/* {this.state.location_error ? (
                <p>{this.state.location_error_message}</p>
              ) : null}
              {this.state.fleetManagerName_error ? (
                <p>{this.state.fleetManagerName_error_message}</p>
              ) : null} */}
            </Message>
          ) : null}
        </Modal.Content>
        <Modal.Actions>
          <Button.Group size="large">
            <Button
              basic
              color="red"
              type="submit"
              onClick={this.handleCloseAddFleet}
            >
              <Icon name="cancel" />
              Cancel
            </Button>
            <Button.Or />
            <Button
              basic
              color="blue"
              type="submit"
              onClick={this.handleAddFleet}
              // disabled={!this.validateForm()}
            >
              <Icon name="add" />
              Add
            </Button>
          </Button.Group>
        </Modal.Actions>
      </Modal>
    );
  }
}
