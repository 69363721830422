import React, { Component } from "react";
import { Button, List, Divider, Image } from "semantic-ui-react";

class OnBoardingConfirmation extends Component {
  constructor(props) {
    super(props);
    this.state = { fleetMgr: "" };
    // this.addDeviceHandler = this.addDeviceHandler.bind(this);
    // this.addFleetHandler = this.addFleetHandler.bind(this);
    this.addUserHandler = this.addUserHandler.bind(this);
  }

  componentDidMount() {
    console.log("onboarding confirm mounted", this.props);
    if (this.props.values.fleetManagerName === "") {
      this.setState({ fleetMgr: "Not Assigned" });
    } else {
      this.setState({ fleetMgr: this.props.values.fleetManagerName });
    }
  }

  addUserHandler(e) {
    this.props.callBackAddUser(e);
  }
  // addFleetHandler(e) {
  //   // this.props.callBackAddFleet(e);
  // }
  // addDeviceHandler = e => {
  //   //this.props.callBackAddDevice(e);
  // };
  saveAndContinue = e => {
    e.preventDefault();
    this.props.nextStep();

    // this.addDeviceHandler(this.props.values);
    // this.addFleetHandler(this.props.values);
    this.addUserHandler(this.props.values);
  };

  back = e => {
    e.preventDefault();
    this.props.prevStep();
  };

  render() {
    const {
      values: {
        firstName,
        lastName,
        phone,
        username,
        diffPassword,
        email,
        password,
        newFleet,
        fleetName,
        fleetNo,
        fleetManagerName,
        newBoat,
        device_id,
        device_eid,
        boatName,
        location,
        callFrom ,
        NoFleetManager = "Not Assigned"
      }
    } = this.props;

    return (
      <div>
        <h1 className="ui centered">Confirm your Details</h1>
        <p>
          Click Confirm if the following details have been correctly entered
        </p>
        <List>
          <Image src="/captain.svg" size="mini" />
          <List.Item>
            <List.Icon name="users" />
            <List.Content>First Name: {firstName}</List.Content>
          </List.Item>
          <List.Item>
            <List.Icon name="users" />
            <List.Content>Last Name: {lastName}</List.Content>
          </List.Item>
          <List.Item>
            <List.Icon name="mail" />
            <List.Content>
              Email: <a>{email}</a>
            </List.Content>
          </List.Item>
          <List.Item>
            <List.Icon name="phone" />
            <List.Content>Phone: {phone} </List.Content>
          </List.Item>
          <List.Item>
            <List.Icon name="user circle" />
            <List.Content>Username: {username}</List.Content>
          </List.Item>
          <Divider section />

          <List.Item>
            <Image src="/fleet.svg" size="mini" />
            <List.Content>Fleet Name: {fleetName} </List.Content>
          </List.Item>

          <List.Item>
            <List.Icon name="user circle" />
            <List.Content>
              Fleet Manager Name: {this.state.fleetMgr}
            </List.Content>
          </List.Item>
          <List.Item>
            <List.Icon name="map marker alternate" />
            <List.Content>Location: {location}</List.Content>
          </List.Item>
          <Divider section />
          <List.Item>
            <Image src="/boat-yatch.svg" size="mini" />
            <List.Content> Device Number: {device_eid}</List.Content>
          </List.Item>
          <List.Item>
            <List.Content>Boat Name: {boatName}</List.Content>
          </List.Item>
        </List>

        <Button basic color="red" onClick={this.back}>
          Back
        </Button>
        <Button basic color="green" onClick={this.saveAndContinue}>
          Confirm
        </Button>
      </div>
    );
  }
}

export default OnBoardingConfirmation;
