import React from "react";
import { Button, Header, Form, Message,Image, Icon, Modal } from "semantic-ui-react";
import FleetDetails from "./FleetDetails";

export default class EditFleet extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      editFleetModelOpen: this.props.editFleetModalOpen,      
      fleetName: this.props.selectedFleet.asset_name,  
      asset_id: this.props.selectedFleet.asset_id,
      asset_eid: this.props.selectedFleet.asset_eid,
      fleetManagerName: this.props.selectedFleet.first_contact,
      location: this.props.selectedFleet.location,
      app_id: this.props.selectedFleet.app_id,
       fleetName_validated: false,
      fleetManagerName_validated: false,
      location_validated: false,
      fleetName_error: false,
      fleetManagerName_error: false,
      location_error: false,
      fleetName_error_message: "Please enter a valid fleet name",
      formValidationError: false,
      fleetManagerName_error_message: "Please enter a valid name",
      location_error_message: "Please enter a valid location"
       };
    this.handleFleetNameChange = this.handleFleetNameChange.bind(this);
    this.handleLocationChange = this.handleLocationChange.bind(this);
    this.handleFleetManagerNameChange = this.handleFleetManagerNameChange.bind(
      this
    );
    this.handleLocationChange = this.handleLocationChange.bind(this);
    this.validateForm = this.validateForm.bind(this);
    this.handleEditFleet = this.handleEditFleet.bind(this);
  }
  componentDidMount() {
    // console.log(" props from   to edit fleet ", this.props);
    // console.log(" states from   to edit fleet ", this.state);
  }
  handleFleetNameChange=(e)=> {
    console.log("fleetname change e",e)
    this.setState({
        fleetName: e
        // fleetName_validated: true,
        // fleetName_error: false
    })
    console.log("fleet name change", this.state.fleetName);
    
    // if( e.target.value!=""|| e.target.value != null){
    //   this.setState({ 
    //     fleetName_validated: true,
    //     fleetName_error: false
    // });
    // }
    // else{
    //   this.setState({
    //     fleetName: e.target.value,
    //     fleetName_validated: true,
    //     fleetName_error: false
    // });
    // }
    this.validateForm();
  }

  handleFleetManagerNameChange(e) {
    this.setState({
        fleetManagerName: e.target.value,
        fleetManagerName_validated: true,
        fleetManagerName_error: false
    });
    this.validateForm();
  }

  handleLocationChange(e) {
      this.setState({
        location: e.target.value,
        location_validated: true,
        location_error: false 
    });
    this.validateForm();
  }
  async handleEditFleet (e) {
    e.preventDefault();
    console.log("formValidationError handleEditFleet",this.state.formValidationError);
    await this.validateForm();
    console.log("formValidationError handleEditFleet",this.state.formValidationError);
    
    if (!this.state.formValidationError) {
    this.setState({ editFleetModelOpen: false });
    this.props.closeModal();
    this.props.callBackEditFleet(this.state)
    }
   
  };

  handleCloseEditFleet = () => {
    this.setState({
      editFleetModelOpen: false
    });
    this.props.closeModal();
  };

  validateForm() {
    if (this.state.fleetName == "") {
      console.log("fleet name is blank");
      console.log("fleet name validateForm", this.state.fleetName);
      this.setState({
        fleetName_validated: false,
        fleetName_error: true,
        formValidationError: true
      });
   //   console.log("fleetName form valudator", this.state.formValidationError);
    } else {
      console.log("fleet name is not blank");
      console.log("fleet name validateForm", this.state.fleetName);
      this.setState({
        fleetName_validated: true,
        fleetName_error: false,
        formValidationError: false
      });
    }
    // if (this.state.fleetManagerName == "") {
    //   this.setState({
    //     fleetManagerName_validated: false,
    //     fleetManagerName_error: true,
    //     formValidationError: true
    //   });
    // } else {
    //   this.setState({
    //     fleetManagerName_validated: true,
    //     fleetManagerName_error: false,
    //     formValidationError: false
    //   });
    // }
    // if (this.state.location == "" || this.state.location == null) {
    //   this.setState({
    //     location_validated: false,
    //     location_error: true,
    //     formValidationError: true
    //   });
    // } else {
    //   this.setState({
    //     location_validated: true,
    //     location_error: false,
    //     formValidationError: false
    //   });
    // }
  }

  render() {
    const { editFleetModelOpen, fleetName, asset_id, asset_eid, fleetManagerName, location, app_id} = this.state;
    const values = { fleetName, asset_id, asset_eid, fleetManagerName, location, app_id, editFleetModelOpen };
    return (
      <Modal
        size={"tiny"}
        dimmer={"blurring"}
        centered={false}
        open={editFleetModelOpen}
        onClose={this.handleCloseEditFleet}
        closeIcon

      >
        <Header>
          <Image 
          floated="left" 
          size="mini" 
          src="/fleet.svg"
           />
          Edit Fleet Details
        </Header>
        <Modal.Content>
          <Form>
            <FleetDetails
              fleetNameFunc={this.handleFleetNameChange}
              fleetName={this.state.fleetName}
              fleetManagerNameFunc={this.handleFleetManagerNameChange}
              fleetManagerName={this.state.fleetManagerName}
              locationFunc={this.handleLocationChange}
              location ={this.state.location}
              values={values}
            />
          </Form>

          {this.state.formValidationError ? (
            <Message negative>
              <Message.Header>Please check the following fields</Message.Header>
              {this.state.fleetName_error ? (
                <p>{this.state.fleetName_error_message}</p>
              ) : null}
              {/* {this.state.location_error ? (
                <p>{this.state.location_error_message}</p>
              ) : null}
              {this.state.fleetManagerName_error ? (
                <p>{this.state.fleetManagerName_error_message}</p>
              ) : null} */}
            </Message>
          ) : null}

        </Modal.Content>
        <Modal.Actions>
          <Button.Group size="large">
            <Button
              basic
              color="red"
              type="submit"
              onClick={this.handleCloseEditFleet}
            >
              <Icon name="cancel" />
              Cancel
            </Button>
            <Button.Or />
            <Button
              basic
              color="green"
              type="submit"
              onClick={this.handleEditFleet}
            //  disabled={!this.validateForm()}
            >
              <Icon name="edit" />
              Update
            </Button>
          </Button.Group>
        </Modal.Actions>
      </Modal>
    );
  }
}
