import React, { Component } from "react";
import {
    Button,
    Card,
    Image,
    Icon,
    Segment,
    Dropdown,
    Label,
    Menu,
    Rail,
    Input,
    Sticky,
    Header
} from "semantic-ui-react";
import { Link } from "react-router-dom";
import { API } from "aws-amplify";
import AddDevice from "./AddDevice";
import EditDevice from "./EditDevice";
import DeleteModal from "../../commons/DeleteModal";
export default class DeviceList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            visible: false,
            deviceList: this.props.values.deviceList,
            deviceDropDownList: this.props.values.deviceDropDownList,
            assetDropDownList: this.props.values.assetDropDownList,
            assets_list_for_device: this.props.values.assets_list_for_device,
            editDeviceModalOpen: false,
            deleteDeviceModalOpen: false,
            searchQuery: "",
            asset_list_by_device: [],
            selectedDevice: {
                id: "",
                asset_name: "",
                asset_id: "",
                device_eid: "",
                name: ""
            }
        };
        this.singleDeviceCard = this.singleDeviceCard.bind(this);
        this.handleEditDeviceModalOpen = this.handleEditDeviceModalOpen.bind(this);
        this.handleEditDeviceModalClose = this.handleEditDeviceModalClose.bind(
            this
        );
        this.updateDeviceHandler = this.updateDeviceHandler.bind(this);
        this.addDeviceHandler = this.addDeviceHandler.bind(this);
        this.handleDeleteDeviceModalOpen = this.handleDeleteDeviceModalOpen.bind(
            this
        );
        this.handleDeleteDeviceModalClose = this.handleDeleteDeviceModalClose.bind(
            this
        );
        this.deleteDeviceHandler = this.deleteDeviceHandler.bind(this);
        //this.handleSearchChange=this.handleSearchChange.bind(this);
    }

    componentDidMount() {
        // console.log("user_fleet_list", this.props);
    }
    handleButtonClick = () => this.setState({ visible: !this.state.visible });
    handleSidebarHide = () => this.setState({ visible: false });
    // handleDrawer = () => {
    //   this.refs.customDrawerReference.handleToggle();
    // };

    backButtonHandler(e) {
        this.props.callBack(e);
    }

    handleDeleteDeviceModalOpen = values => {
        this.setState({
            deleteDeviceModalOpen: true,
            selectedDevice: {
                id: values.id,
                asset_id: values.asset_id,
                asset_name: values.asset_name,
                device_eid: values.device_eid,
                name: values.name
            }
        });
    };

    handleDeleteDeviceModalClose = () => {
        this.setState({ deleteDeviceModalOpen: false });
    };

    deleteDeviceHandler(e) {
        console.log("deleteDeviceHandler", e);
        this.props.callBackDeleteDevice(e);
    }

    get_asset_list_by_device(device_id) {
        const path = `/assets/get_all_asset_devices`;
        return API.get("boatsecure", path, {
            queryStringParameters: { device_id }
        });
    }

    async handleEditDeviceModalOpen(values) {
        var temp_asset_list_by_device = await this.get_asset_list_by_device(values.id);
        console.log("temp_asset_list_by_device", temp_asset_list_by_device);
        var asset_list_by_device = []
        if (temp_asset_list_by_device.data != null) {
            for (let i = 0; i < temp_asset_list_by_device.data.length; i++) {
                asset_list_by_device.push(
                    temp_asset_list_by_device.data[i].attributes.asset_id
                );
            }
        }

        await this.setState({
            asset_list_by_device: asset_list_by_device
        });
        this.setState({
            editDeviceModalOpen: true,
            selectedDevice: {
                id: values.id,
                asset_id: values.asset_id,
                asset_name: values.asset_name,
                device_eid: values.device_eid,
                name: values.name,
                device_assets_list: asset_list_by_device,
                assets_list_for_device: this.props.values.assets_list_for_device
            }
        });
    }
    handleEditDeviceModalClose() {
        this.setState({ editDeviceModalOpen: false });
    }

    updateDeviceHandler(e, asset_id) {
        this.props.callBackEditDevice(e, asset_id);
    }

    addDeviceHandler(e) {
        console.log("addDeviceHandler in device list", e);
        this.props.callBackAddDevice(e);
    }

    handleChange = (e, data) => {
        let searchQuery = data.value;
        this.setState({ deviceList: this.props.values.deviceList });
        let searchdeviceList = this.props.values.deviceList.data;
        let searchdeviceListResponse = [];
        for (let i = 0; i < searchdeviceList.length; i++) {
            if (
                searchdeviceList[i].attributes.name
                    .toLowerCase()
                    .includes(searchQuery.toLowerCase()) ||
                searchdeviceList[i].attributes.device_eid
                    .toLowerCase()
                    .includes(searchQuery.toLowerCase())
            ) {
                searchdeviceListResponse.push(searchdeviceList[i]);
            }
        }
        this.setState({ deviceList: searchdeviceListResponse });
    };

    singleDeviceCard = item => {
        const id = item.id;
        let asset_name = "";
        const asset_id = item.attributes.asset_id;
        const device_eid = item.attributes.device_eid;
        const name = item.attributes.name;
        for (let i = 0; i < this.state.assetDropDownList.length; i++) {
            if (this.state.assetDropDownList[i].key === item.attributes.asset_id) {
                asset_name = this.state.assetDropDownList[i].text;
            }
        }
        const { deviceList, deviceDropDownList } = this.state;
        let values = {
            id,
            asset_name,
            asset_id,
            device_eid,
            name,
            deviceList,
            deviceDropDownList
        };
        return (
            <Card color="blue" key={item.id}>
                <Card.Content>
                    <Image floated="right" size="mini" src="/boat-yatch.svg" />
                    <Card.Header>
                        {" "}
                        <label> Boat Name: </label>
                        {item.attributes.name}
                    </Card.Header>
                    <Card.Description>
                        <label>
                            {" "}
                            <strong>Device Eid: </strong>{" "}
                        </label>{" "}
                        <strong>{item.attributes.device_eid}</strong>
                        {/*<br />
                     <label> 
              <strong>Fleet Name: </strong>{" "}
            </label>{" "}
            {asset_name}*/}
                    </Card.Description>
                </Card.Content>
                <Card.Content extra>
                    <div className="ui two buttons">
                        <Button
                            basic
                            color="green"
                            fluid
                            size="large"
                            onClick={() => this.handleEditDeviceModalOpen(values)}
                        >
                            <Icon name="edit" />
                            Edit
            </Button>
                        <Button
                            basic
                            color="red"
                            fluid
                            size="large"
                            onClick={() => this.handleDeleteDeviceModalOpen(values)}
                        >
                            <Icon name="delete" />
                            Delete
            </Button>
                    </div>
                </Card.Content>
            </Card>
        );
    };

    render() {
        const {
            assetList,
            assetDropDownList,
            deviceList,
            deviceDropDownList,
            assets_list_for_device,
            searchQuery,
            value
        } = this.state;
        const values = {
            assetList,
            assetDropDownList,
            assets_list_for_device,
            deviceList,
            deviceDropDownList
        };
        var deviceEntries =
            this.state.deviceList.data == null
                ? this.state.deviceList
                : this.state.deviceList.data;
        var listDeviceItems = deviceEntries.map(this.singleDeviceCard);
        const menustyle = { border: "1px  solid #2185d0" };
        const dropdownstyle = { width: "50%" };
        const SegmentStyle = { height: "100%" };
        return (
            <Segment
                color="blue"
                padded="very"
                raised
                size="mini"
                style={SegmentStyle}
            >
                {/* <AddDevice 
           assetDropDownList={this.state.assetDropDownList}
           callBackAddDevice={this.addDeviceHandler}
        /> */}

                <Header as="h3" color="blue">
                    Device List
        </Header>
                <Menu className="header-menu" size="tiny" style={menustyle}>
                    <Menu.Item style={dropdownstyle}>
                        <Input
                            icon="search"
                            placeholder="Search..."
                            basic="true"
                            color="blue"
                            fluid
                            style={menustyle}
                            onChange={this.handleChange}
                        />
                    </Menu.Item>

                    <Menu.Item position="right">
                        <Link to="/">
                            <Button
                                basic
                                color="blue"
                                floated="right"
                                onClick={() => this.backButtonHandler(1)}
                            >
                                {" "}
                                <Icon name="left arrow" />
                                Back
              </Button>
                        </Link>
                    </Menu.Item>
                </Menu>
                <br /> <br />
                <Card.Group>{listDeviceItems}</Card.Group>
                {this.state.editDeviceModalOpen ? (
                    <EditDevice
                        closeModal={this.handleEditDeviceModalClose}
                        editDeviceModalOpen={this.state.editDeviceModalOpen}
                        selectedDevice={this.state.selectedDevice}
                        assetDropDownList={this.state.assetDropDownList}
                        assetsListForDevice={this.props.assets_list_for_device}
                        callBackEditDevice={this.updateDeviceHandler}
                    />
                ) : null}
                {this.state.deleteDeviceModalOpen ? (
                    <DeleteModal
                        closeModal={this.handleDeleteDeviceModalClose}
                        deleteModalOpen={this.state.deleteDeviceModalOpen}
                        selectedItemName={this.state.selectedDevice.name}
                        selectedItemId={this.state.selectedDevice.id}
                        callBackDelete={this.deleteDeviceHandler}
                        additionalMessage=""
                        additionalMessageEnabled={false}
                        values={values}
                    />
                ) : null}
            </Segment>
        );
    }
}
