import React from "react";
import {
  Button,
  Header,
  Form,
  Icon,
  Image,
  Modal,
  Dropdown,
  Label
} from "semantic-ui-react";

export default class EditDevice extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      editBoatModelOpen: this.props.editDeviceModalOpen,
      device_id: this.props.selectedDevice.id,
      device_eid: this.props.selectedDevice.device_eid,
      boatName: this.props.selectedDevice.name,
      asset_id: this.props.selectedDevice.asset_id,
      asset_name: this.props.selectedDevice.asset_name,
        assetDropDownList: this.props.assetDropDownList,
        assets_list_for_device:[],
        user_fleet_list:[],
        existing_fleet_list:[],
        searchQuery: "",
        selectedDevice: {},
        current_assets: [],
        add_fleet_list: [],
        delete_fleet_list:[],
      boatName_error: false,
      boatName_error_message: "Please enter a valid boat name",
    };
    this.handleBoatNameChange = this.handleBoatNameChange.bind(this);
    this.handleEditBoat = this.handleEditBoat.bind(this);
    this.handlerBoatNameValidator = this.handlerBoatNameValidator.bind(this);
      this.handleAddFleets = this.handleAddFleets.bind(this);
      this.handleSelectFLeet = this.handleSelectFLeet.bind(this);
    }

    //static getDerivedStateFromProps(props, state) {
    //    var udate_state = {}
    //    if (props.selectedDevice !== state.selectedDevice) {
    //        console.log(" 1111111111111");
    //        udate_state.user_fleet_list = props.selectedDevice.device_assets_list
    //        udate_state.assets_list_for_device = props.selectedDevice.assets_list_for_device
    //    }

       
        
    //    return udate_state
    //}

    async componentDidMount() {
        if (this.props.selectedDevice.device_assets_list != null && this.props.selectedDevice.device_assets_list.length>0) {
            await this.setState({ user_fleet_list: this.props.selectedDevice.device_assets_list });
            await this.setState({ existing_fleet_list: this.props.selectedDevice.device_assets_list });
        } 

        if (this.props.selectedDevice.assets_list_for_device != null && this.props.selectedDevice.assets_list_for_device.length > 0) {
            await this.setState({ assets_list_for_device: this.props.selectedDevice.assets_list_for_device });
        } 
        
    console.log(" props from   to edit device ", this.props);
  }

  handleBoatNameChange(e) {
    this.setState({ boatName: e.target.value });
  }

  handlerBoatNameValidator(e) {
    console.log("handlerBoatNameValidator", e.target.value);
    if (e.target.value.length > 0) {
      this.setState({ boatName_error: false });
      this.handleBoatNameChange(e)
    } else {
      this.setState({ boatName_error: true });
    }
  }

  handleEditBoat(e) {
    e.preventDefault();
    console.log("handleEditBoat", this.state);
    if(this.state.boatName ==""){
      this.setState({ boatName_error: true });
    }
   else
   {
      this.setState({ addBoatModelOpen: false });
    this.props.closeModal();
    this.props.callBackEditDevice(this.state, this.state.asset_id); 
  }
    }

    handleAddFleets(e, { value }) {
        console.log("handleEditBoat", value);
        this.setState({
            assets_list_for_device: [{ text: value, value }, ...this.state.assets_list_for_device]
        });
    }


  handleCloseEditBoat = () => {
    this.setState({
      editBoatModelOpen: false
    });
    this.props.closeModal();
  };
     handleSelectFLeet(e, data) {
        var selected_Fleets = data.value
        this.setState({ user_fleet_list: data.value }); 
        console.log("selected_Fleets", selected_Fleets);
        console.log("data", data);
        console.log("e.target", e);
       
    //const { key } = data.options.find(o => o.value === data.value);
    //const { text } = data.options.find(o => o.value === data.value);
    //console.log("Key", key);
    //console.log("text", text);
    //this.setState({ asset_name: text });
    //this.setState({ asset_id: key });
  };
  validateForm() {
     console.log("boatName_error",this.state.boatName_error);
    
    return        !this.state.boatName_error ;
  }
    render() {
        console.log("state", this.state);
    const {
      boatName,
      device_id,
      addBoatModelOpen,
      deviceList,
        deviceDropDownList,
        user_fleet_list,
        assets_list_for_device
    } = this.state;
    const values = {
      boatName,
      device_id,
      addBoatModelOpen,
      deviceList,
      deviceDropDownList
    };
    return (
      <Modal
        size={"tiny"}
        dimmer={"blurring"}
        open={this.state.editBoatModelOpen}
        centered={false}
        closeIcon
        onClose={this.handleCloseEditBoat}
      >
        <Header>
          <Image floated="left" size="mini" src="/boat-yatch.svg" />
          Edit Boat Details
        </Header>
        <Modal.Content>
          <Form>
            <label>Boat Name</label>
            <input
              placeholder="Boat Name"
              className="form-input"
              name={this.props.selectedDevice.name}
              defaultValue={this.props.selectedDevice.name}
             // onChange={this.handleBoatNameChange}
             onBlur={this.handlerBoatNameValidator}
            />
             {this.state.boatName_error ? (
            <Label
              //  basic
              color="red"
              pointing
            >
              {this.state.boatName_error_message}
            </Label>
          ) : null}
            <label>Device EID</label>
            <input
              placeholder="Device EID"
              className="form-input"
              readOnly
              name={this.props.selectedDevice.device_eid}
              defaultValue={this.props.selectedDevice.device_eid}
              // onChange={this.ha}
            />
            <Form.Field>
              <label>Fleet Name</label>
              <Dropdown
                placeholder="Existing Fleet"
                search
                multiple
                selection
                            options={assets_list_for_device}
                            className="form-input"
                            allowAdditions
                //searchQuery={this.state.searchQuery}
                //text={this.props.assetDropDownList.text}
                            value={user_fleet_list}
                onChange={this.handleSelectFLeet}
                            onAddItem={this.handleAddFleets}
              />
            </Form.Field>
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button.Group size="large">
            <Button
              basic
              color="red"
              type="submit"
              onClick={this.handleCloseEditBoat}
            >
              <Icon name="cancel" />
              Cancel
            </Button>
            <Button.Or />
            <Button
              basic
              color="green"
              type="submit"
              onClick={this.handleEditBoat}
            //  disabled={!this.validateForm()}
            >
              <Icon name="add" />
              Update
            </Button>
          </Button.Group>
        </Modal.Actions>
      </Modal>
    );
  }
}
