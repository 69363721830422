import React from "react";
import { Button, Modal, Message, Icon } from "semantic-ui-react";

export default class DeleteModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      deleteModalOpen: this.props.deleteModalOpen,
      deleteItemName: this.props.selectedItemName,
      deleteItemId: this.props.selectedItemId,
      additionalMessage: this.props.additionalMessage,
      additionalMessageEnabled: this.props.additionalMessageEnabled
    };
    this.deleteHandler = this.deleteHandler.bind(this);
  }
  componentDidMount() {
    // console.log(" props from   to delete modal ", this.props);
    // console.log(" states from   to del modal ", this.state);
  }
  handleCloseDeleteModal = () => {
    this.setState({ deleteModalOpen: false });
    this.props.closeModal();
  };

  deleteHandler(e) {
    e.preventDefault();
    this.setState({ deleteModalOpen: false });
    this.props.closeModal();
    this.props.callBackDelete(this.state);
  }

  render() {
    return (
      <Modal
        size={"mini"}
        dimmer={"blurring"}
        open={this.state.deleteModalOpen}
        onClose={this.handleCloseDeleteModal}
        closeIcon
      >
        <Modal.Content>
          <p>
            Are you sure you want to remove <b> {this.state.deleteItemName} </b>{" "}
            ?
          </p>
          {this.state.additionalMessageEnabled ? (
            <Message negative> {this.state.additionalMessage} </Message>
          ) : null}
        </Modal.Content>
        <Modal.Actions>
          <Button.Group size="small">
            <Button
              basic
              color="red"
              type="submit"
              onClick={this.handleCloseDeleteModal}
            >
              <Icon name="cancel" />
              Cancel
            </Button>
            <Button.Or />
            <Button
              basic
              color="green"
              type="submit"
              onClick={this.deleteHandler}
            >
              <Icon name="check" />
              Confirm
            </Button>
          </Button.Group>
        </Modal.Actions>
      </Modal>
    );
  }
}
