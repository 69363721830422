import React from "react";
import { Form,Label } from "semantic-ui-react";
import PropTypes from "prop-types";

class FleetDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fleetName_error: false,
      fleetName_error_message: "Please enter a valid fleet name",
      fleetManagerName_error: false,
      fleetManagerName_error_message: "Please enter a valid name",
      location_error: false,
      location_error_message: "Please enter a valid location",
      boatName_error: false,
      boatName_error_message: "Please enter a valid boat name",
    };
    this.handlerFleetNameValidator = this.handlerFleetNameValidator.bind(this);
    this.handlerManagerNameValidator = this.handlerManagerNameValidator.bind(this);
    this.handlerLocationValidator = this.handlerLocationValidator.bind(this);
  }
  componentDidMount() {
    console.log(" props from  fleet list ", this.props);
  }
  handlerFleetNameValidator(e) {
    console.log("handlerFleettnameValidator", e.target.value);
    if (e.target.value.length > 0) {
      this.setState({ fleetName_error : false });
      // this.props.fleetNameFunc(e);
    } else {
      this.setState({ fleetName_error: true });
    }
    this.props.fleetNameFunc(e.target.value);
  }
  handlerManagerNameValidator(e) {
    console.log("handlerManagernameValidator", e.target.value);
    if (e.target.value.length > 0) {
      this.setState({ fleetManagerName_error: false });
      this.props.fleetManagerNameFunc(e);
    } else {
      this.setState({ fleetManagerName_error: true });
    }
  }
  handlerLocationValidator(e) {
    console.log("handlerLocationValidator", e.target.value);
    if (e.target.value.length > 0) {
      this.setState({ location_error: false });
      this.props.locationFunc(e);
    } else {
      this.setState({ location_error: true });
    }
  }
  render() {
    const { values } = this.props;
    return (
      <div>
        <Form.Field>
          <label>Fleet Name</label>
          <input
            placeholder="Fleet Name"
            className="form-input"
            name={this.props.fleetName}
            // value={props.fleetName}
            defaultValue={values.fleetName}
           // onChange={this.props.fleetNameFunc}
         //  onBlur={this.handlerFleetNameValidator}
         onChange={this.handlerFleetNameValidator}
          />
           {this.state.fleetName_error ? (
            <Label
              //  basic
              color="red"
              pointing
            >
              {this.state.fleetName_error_message}
            </Label>
          ) : null}
        </Form.Field>
        <Form.Field>
          <label>Location</label>
          <input
            placeholder="Location"
            className="form-input"
            name={this.props.location}
            //      value={props.location}
            defaultValue={values.location}
           // onChange={this.props.locationFunc}
           onBlur={this.handlerLocationValidator}
          />
           {/* {this.state.location_error ? (
            <Label
              //  basic
              color="red"
              pointing
            >
              {this.state.location_error_message}
            </Label>
          ) : null} */}
        </Form.Field>
        <Form.Field>
          <label>Fleet Manager's Name</label>
          <input
            placeholder="Fleet Managers Name"
            className="form-input"
            name={this.props.fleetManagerName}
            //      value={props.fleetManagerName}
            defaultValue={values.fleetManagerName}
           // onChange={this.props.fleetManagerNameFunc}
           onBlur={this.handlerManagerNameValidator}
          />
           {/* {this.state.fleetManagerName_error ? (
            <Label
              //  basic
              color="red"
              pointing
            >
              {this.state.fleetManagerName_error_message}
            </Label>
          ) : null} */}
        </Form.Field>
      </div>
    );
  }
}

FleetDetails.propTypes = {
  fleetNameFunc: PropTypes.func.isRequired,
  fleetName: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  placeholder: PropTypes.string,

  fleetManagerNameFunc: PropTypes.func,//.isRequired,
  fleetManagerName: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    //.isRequired,

  locationFunc: PropTypes.func,//.isRequired,
  location: PropTypes.oneOfType([PropTypes.string, PropTypes.number]) 
  //.isRequired,
};

export default FleetDetails;
