import React, { Component } from "react";
import { Icon, Image, Segment, Step, Button } from "semantic-ui-react";
import { Link } from "react-router-dom";
import FleetnBoatOnBoarding from "./FleetnBoatOnBoarding";
import UserOnBoarding from "./UserOnBoarding";
import OnBoardingConfirmation from "./OnBoardingConfirm";
import OnBoardingSuccess from "./OnBoardingSuccess";
import { API } from "aws-amplify";

export default class OnBoarding extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      step: 1,
      firstName: "",
      lastName: "",
      phone: "000000",
      username: "",
      diffPassword: false,
      email: "",
      password: this.props.values.password, //"BoatSecure@1",
      newFleet: false,
      fleetName: "",
      fleetNo: "notNow",
      asset_eid: "",
      fleetManagerName: "",
      newBoat: false,
      device_id: "notNow",
      device_eid: "",
      boatName: "",
      test: "initial",
      userSegment: true,
      fleetnBoatSegment: false,
      location: "",
      confirmSegment: false,
      callFrom: "OnBoarding",
      singleAsset: {
        key: "",
        text: "",
        value: ""
      },
      assetList: this.props.values.assetList,
      assetDropDownList: this.props.values.assetDropDownList,
      singleDevice: {
        key: "",
        text: "",
        value: ""
      },
      deviceList: this.props.values.deviceList,
      deviceDropDownList: this.props.values.deviceDropDownList,
      singleUser: {
        key: "",
        text: "",
        value: ""
      },
      userList: this.props.values.userList,
      emailValidated: false,
      usernameValidated: false,
      firstNameValidated: false,
      lastNameValidated: false,
      phoneNumberValidated: false
    };
    this.handlePasswordChange = this.handlePasswordChange.bind(this);
    this.getUserOnBoardingData = this.getUserOnBoardingData.bind(this);
    this.getFleetnBoatOnBoardingData = this.getFleetnBoatOnBoardingData.bind(
      this
    );
    this.backButtonHandler = this.backButtonHandler.bind(this);
    this.addUserHandler = this.addUserHandler.bind(this);
  }
  componentDidMount() {
    console.log(" props from   onboarding ", this.props);
  }

  handlePasswordChange(e) {
    this.setState({ password: e.target.value });
  }

  handleButtonClick = () => this.setState({ visible: !this.state.visible }); // not using

  backButtonHandler(e) {
    this.props.callBack(e);
  }

  nextStep = () => {
    const { step } = this.state;
    this.setState({
      step: step + 1
    });
    switch (step) {
      case 1:
        this.setState({
          userSegment: false,
          fleetnBoatSegment: true,
          confirmSegment: false
        });
        break;
      case 2:
        this.setState({
          userSegment: false,
          fleetnBoatSegment: false,
          confirmSegment: true
        });
        break;
      case 3:
        this.setState({
          userSegment: true,
          fleetnBoatSegment: false,
          confirmSegment: false
        });
        break;
      case 4:
    }
  };

  prevStep = () => {
    const { step } = this.state;
    this.setState({
      step: step - 1
    });
    switch (step) {
      case 1:
        this.setState({
          userSegment: false,
          fleetnBoatSegment: true,
          confirmSegment: false
        });
        break;
      case 2:
        this.setState({
          userSegment: true,
          fleetnBoatSegment: false,
          confirmSegment: false
        });
        break;
      case 3:
        this.setState({
          userSegment: false,
          fleetnBoatSegment: true,
          confirmSegment: false
        });
        break;
      case 4:
    }
  };

  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
  };

  getUserOnBoardingData(userDetailsData) {
    console.log("OnBoarding ", userDetailsData);
    this.setState({
      firstName: userDetailsData.firstName,
      lastName: userDetailsData.lastName,
      phone: userDetailsData.phoneNumber,
      username: userDetailsData.userName,
      diffPassword: userDetailsData.diffPassword,
      email: userDetailsData.email,
      password: userDetailsData.password
    });
  }

  getFleetnBoatOnBoardingData(FleetnBoatDetailsData) {
    console.log("OnBoarding FnB", FleetnBoatDetailsData);
    this.setState({
      newFleet: FleetnBoatDetailsData.newFleetisChecked,
      fleetName: FleetnBoatDetailsData.fleetName,
      fleetNo: FleetnBoatDetailsData.fleetNo,
      fleetManagerName: FleetnBoatDetailsData.fleetManagerName,
      newBoat: FleetnBoatDetailsData.addBoatisChecked,
      device_id: FleetnBoatDetailsData.device_id,
      device_eid: FleetnBoatDetailsData.device_eid,
      boatName: FleetnBoatDetailsData.boatName,
      location: FleetnBoatDetailsData.location
    });
  }
  addUserHandler(e) {
    this.props.callBackAddUser(e);
  }

  render() {
    const { step } = this.state;
    const {
      firstName,
      lastName,
      phone,
      username,
      diffPassword,
      email,
      password,
      newFleet,
      fleetName,
      fleetNo,
      fleetManagerName,
      newBoat,
      device_id,
      device_eid,
      boatName,
      assetList,
      singleAsset,
      deviceList,
      assetDropDownList,
      location,
      deviceDropDownList,
      emailValidated,
      usernameValidated,
      firstNameValidated,
      lastNameValidated,
      phoneNumberValidated,
      callFrom
    } = this.state;
    const values = {
      firstName,
      lastName,
      phone,
      username,
      diffPassword,
      email,
      password,
      newFleet,
      fleetName,
      fleetNo,
      fleetManagerName,
      newBoat,
      device_id,
      device_eid,
      boatName,
      assetList,
      singleAsset,
      deviceList,
      assetDropDownList,
      location,
      deviceDropDownList,
      emailValidated,
      usernameValidated,
      firstNameValidated,
      lastNameValidated,
      phoneNumberValidated,
      callFrom
    };
    return (
      <Segment color="blue" padded="very" raised size="mini">
        <Link to="/">
          <Button
            basic
            color="blue"
            floated="right"
            onClick={() => this.backButtonHandler(1)}
          >
            <Icon name="left arrow" />
            Back
          </Button>
        </Link>
        <br /> <br />
        <Segment.Group horizontal>
          <Segment>
            <Step.Group vertical>
              <Step
                active={this.state.userSegment}
                className="userDetails-step"
                id="userDetails-step"
              >
                <Step.Content>
                  <Image src="/captain.svg" size="mini" />
                  <Step.Title>User Details</Step.Title>
                </Step.Content>
              </Step>

              <Step
                active={this.state.fleetnBoatSegment}
                className="fleetnBoat-Step"
              >
                <Step.Content>
                  <Image src="/fleet.svg" size="mini" />
                  <Step.Title>Fleet Details</Step.Title>
                </Step.Content>
              </Step>

              <Step active={this.state.confirmSegment} className="confirm-step">
                <Step.Content>
                  <Image src="/confirm.png" size="mini" />
                  <Step.Title>Confirm Details</Step.Title>
                </Step.Content>
              </Step>
            </Step.Group>
          </Segment>
          <Segment attached>
            {(() => {
              switch (step) {
                case 1:
                  return (
                    <UserOnBoarding
                      nextStep={this.nextStep}
                      sendData={this.getUserOnBoardingData}
                      values={this.state}
                      callBackButton={this.backButtonHandler}
                    />
                  );
                case 2:
                  return (
                    <FleetnBoatOnBoarding
                      nextStep={this.nextStep}
                      prevStep={this.prevStep}
                      sendData={this.getFleetnBoatOnBoardingData}
                      values={values}
                    />
                  );
                case 3:
                  return (
                    <OnBoardingConfirmation
                      nextStep={this.nextStep}
                      prevStep={this.prevStep}
                      values={values}
                      callBackAddUser={this.addUserHandler}
                      callBackAddFleet={this.addFleetHandler}
                      callBackAddDevice={this.addDeviceHandler}
                    />
                  );
                case 4:
                  return <OnBoardingSuccess />;
              }
            })()}
          </Segment>
        </Segment.Group>
      </Segment>
    );
  }
}
