import React from "react";
import { Form, Checkbox, Input, Label } from "semantic-ui-react";
import Password from "../../commons/Password";
import PropTypes from "prop-types";

export default class UserDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      newPassword: this.props.values.diffPassword,
      password: "",
      userList: this.props.values.userList,
      email_exist: false,
      email_exist_prompt: false,
      email_error: false,
      email_error_message: "",
      username_exist: false,
      username_exist_prompt: false,
      username_error: this.props.values.usernameValidated,//false,
      username_error_message: "",
      firstname_error: false,
      firstname_error_message: "Please enter a valid first name",
      lastname_error: false,
      lastname_error_message: "Please enter a valid last name",
      phonenumber_error: false,
      phonenumber_error_message: "Please enter a valid phone number"
    };
    this.handleNewPassworChecked = this.handleNewPassworChecked.bind(this);
    this.handlePasswordChange = this.handlePasswordChange.bind(this);
    this.sendPassTst = this.sendPassTst.bind(this);
    this.handleExistingUsername = this.handleExistingUsername.bind(this);
    this.handleExistingEmail = this.handleExistingEmail.bind(this);
    this.handlerFirstnameValidator = this.handlerFirstnameValidator.bind(this);
    this.handlerLastnameValidator = this.handlerLastnameValidator.bind(this);
    this.handlerPhoneNumberValidator = this.handlerPhoneNumberValidator.bind(
      this
    );
  }

  componentDidMount() {
    console.log("Userdetails", this.props);
  }

  handlePasswordChange(e) {
    this.setState({ password: e.target.value });
    this.sendPassTst(e.target.value);
  }

  sendPassTst(e) {
    this.setState({ password: e });
    this.props.sendPassword(e);
    this.props.sendDiffPassword(this.state.newPassword);
  }

  handleNewPassworChecked() {
    this.setState({ newPassword: !this.state.newPassword });
  }

  handleExistingEmail(e) {
    console.log("handleExistingEmail", e.target.value);
    let email_exist_check = this.state.email_exist;

    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(e.target.value)) {
      for (let i = 0; i < this.state.userList.data.length; i++) {
       
        if (
          e.target.value == this.state.userList.data[i].attributes.data.email
        ) {
          this.setState({
            email_exist_prompt: true,
            email_error: true,
            email_error: true,
            email_error_message: "This email is already registered"
          });
          break;
        } else {
          this.setState({
            email_exist: false,
            email_error: false,
            email_exist_prompt: false
          });
        }
      }
      if (email_exist_check == false) {
        this.props.emailFunc(e);
      }
      else{
      }
    } else {
      this.setState({
        email_error: true,
        email_error_message: "Please enter a correct email"
      });
    }
    // this.setState({ device_eid: e.target.value });
  }

  handleExistingUsername(e) {
    console.log("handleExistingUsername", e.target.value);
    let username_exist_check = this.state.username_exist;
    if (e.target.value.length > 2) {
      for (let i = 0; i < this.state.userList.data.length; i++) {    
        if (
          (this.state.userList.data[i].attributes.username ||
            this.state.userList.data[i].attributes.username2) == e.target.value
        ) {
          this.setState({
            username_exist_prompt: true,
            username_error: true,
            username_error_message: "This username is already registered"
          });
          break;
        } else {
          this.setState({
            username_exist: false,
            username_exist_prompt: false,
            username_error: false
          });
        }
      }
      if (username_exist_check == false) {
        this.props.userNameFunc(e);
      }
    } else {
      this.setState({
        username_exist_prompt: true,
        username_error: true,
        username_error_message: "Username should be minimum 3 characters long"
      });
    }
  }

  handlerFirstnameValidator(e) {
    console.log("handlerFirstnameValidator", e.target.value);
   // console.log("Userdetails", this.props);
    if (e.target.value.length > 0) {
      this.setState({ firstname_error: false });
      this.props.firstNameFunc(e);
    } else {
      this.setState({ firstname_error: true });
    }
  }

  handlerLastnameValidator(e) {
    console.log("handlerLastnameValidator", e.target.value);
  
    if (e.target.value.length > 0) {
      this.setState({ lastname_error: false });
      this.props.lastNameFunc(e);
    } else {
      this.setState({ lastname_error: true });
    }
  }

  isNumber(n) { 
    return !isNaN(parseFloat(n)) && !isNaN(n - 0)
   }

  handlerPhoneNumberValidator(e) {
    console.log("handlerPhoneNumberValidator",typeof( e.target.value));
    let isNumber = this.isNumber(e.target.value);
    console.log("isNumber",isNumber);
    //&&(e.target.value.length > 5)
    
    if(e.target.value.length>0){
    if (isNumber) {
      this.setState({ phonenumber_error: false });
      this.props.phoneFunc(e);
    } else {
      this.setState({ phonenumber_error: true });
    }}



  }

  render() {
    const { values } = this.props;

    return (
      <div>
        <Form.Field>
          <label>First Name</label>
          <input
            placeholder="First Name"
            className="form-input"
            name={this.props.firstName}
            //  value={this.props.firstName}
            defaultValue={values.firstName}
            //  onChange={this.props.firstNameFunc}
            onBlur={this.handlerFirstnameValidator}
          />

          {this.state.firstname_error ? (
            <Label
              //  basic
              color="red"
              pointing
            >
              {this.state.firstname_error_message}
            </Label>
          ) : null}
        </Form.Field>
        <Form.Field>
          <label>Last Name</label>
          <input
            placeholder="Last Name"
            className="form-input"
            name={this.props.lastName}
            // value={this.props.lastName}
            defaultValue={values.lastName}
            //  onChange={this.props.lastNameFunc}
            onBlur={this.handlerLastnameValidator}
          />

          {this.state.lastname_error ? (
            <Label
              //  basic
              color="red"
              pointing
            >
              {this.state.lastname_error_message}
            </Label>
          ) : null}
        </Form.Field>
        <Form.Field>
          <label>Email</label>
          <Input
            placeholder="Email"
            className="form-input"
            name={this.props.email}
            //  value={this.props.email}
            defaultValue={values.email}
             onChange={this.handleExistingEmail}
          //  onBlur={this.handleExistingEmail}
          />
{/* //,this.state.email_error */}
       {this.state.email_error ? (
            <Label
              //  basic
              color="red"
              pointing
            >
              {this.state.email_error_message}
            </Label>
          ) : null}
        </Form.Field>
        <Form.Field>
          <label>Phone</label>
          <Input
            label="+64"
            placeholder="Phone wNumber"
            className="form-input"
            name={this.props.phoneNumber}
            // value={this.props.phoneNumber}
            defaultValue={values.phone == "000000"? "": values.phone.replace("+64", "")}
            //defaultValue={values.phone.replace("+64", "")}
            // onChange={this.props.phoneFunc}
            onBlur={this.handlerPhoneNumberValidator}
          />
          {this.state.phonenumber_error ? (
            <Label
              //  basic
              color="red"
              pointing
            >
              {this.state.phonenumber_error_message}
            </Label>
          ) : null}
        </Form.Field>
        <Form.Field>
          <label>Username</label>
          <input
            placeholder="Userame"
            className="form-input"
            name={this.props.userName}
            //  value={this.props.userName}
            defaultValue={values.username}
             onChange={this.handleExistingUsername}
            //  onBlur={this.handleExistingUsername}
          />

          {this.state.username_error ? (
            <Label
              //  basic
              color="red"
              pointing
            >
              {this.state.username_error_message}
            </Label>
          ) : null}
        </Form.Field>

        <Form.Field>
          <Checkbox
            toggle
            onChange={this.handleNewPassworChecked}
            checked={this.state.newPassword}
            label={{ children: "Do you want to enter a different password?" }}
          />
        </Form.Field>
        {this.state.newPassword ? (
          <Form.Field>
            <label>Password</label>
            <Password
              controlFunc={this.handlePasswordChange}
              password={this.state.password}
              name={this.props.password}
              value={this.state.password}
              placeholder={"Password"}
            />
          </Form.Field>
        ) : null}
      </div>
    );
  }
}
UserDetails.propTypes = {
  // {/*{this.handlePasswordChange}    */}

  firstNameFunc: PropTypes.func.isRequired,
  firstName: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  placeholder: PropTypes.string,

  lastNameFunc: PropTypes.func.isRequired,
  lastName: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,

  emailFunc: PropTypes.func.isRequired,
  email: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,

  phoneFunc: PropTypes.func.isRequired,
  phoneNumber: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,

  userNameFunc: PropTypes.func.isRequired,
  userName: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired

  //diffPasswordFunc: PropTypes.func.isRequired,
  //diffPassword: PropTypes.oneOfType([
  //    PropTypes.string,
  //    PropTypes.number,
  //]).isRequired,

  //controlFunc: PropTypes.func,
  //password: PropTypes.oneOfType([
  //    PropTypes.string,
  //    PropTypes.number
  //])
};
