import React, { Component } from 'react';
import { Button, Header, Form,  Modal } from 'semantic-ui-react';
import Auth from '@aws-amplify/auth';
import {   JS  } from '@aws-amplify/core';

export default class ForgotPassword extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isParentOpen: this.props.forgotPasswordModalOpen,
            userName: this.props.CognitoUser.username,
            modalContent: "Forgot Password",
            email: "",
            password: "",
            confirmPassword: "",
            cognitoUser: this.props.CognitoUser,
            submitForgotUsername: false,
            passwordVisible: this.props.disable
        };
        this.handleForgotPassword = this.handleForgotPassword.bind(this);
    }
    componentDidMount() {
        console.log("forgot password copy mounted", this.props);
        if (this.props.disable === true) {
            this.setState({
                modalContent: "You have to set a new Password"
            });
        }
    }

    handleCloseForgotPasswordModal = () => {
        this.setState({
            isParentOpen: false
        });
        this.props.closeModal();
    }
   


    handleChange = event => {
        this.setState({
            [event.target.id]: event.target.value
        });

    }

    checkContact(user) {
        if (!Auth || typeof Auth.verifiedContact !== 'function') {
            throw new Error('No Auth module found, please ensure @aws-amplify/auth is imported');
        }
        Auth.verifiedContact(user)
            .then(data => {
                if (!JS.isEmpty(data.verified)) {
                    this.changeState('signedIn', user);
                } else {
                    user = Object.assign(user, data);
                    this.changeState('verifyContact', user);
                }
            });
    }


    handleChangePassword = async event => {
        event.preventDefault();
         if (this.state.password === this.state.confirmPassword) {            
        
            const { requiredAttributes }  = this.state.cognitoUser.challengeParam;
            const attrs = objectWithProperties(this.state.password, requiredAttributes);
            try {
                console.log("force password try")
                await Auth.completeNewPassword(this.state.cognitoUser, this.state.password, attrs)
                    .then(() => {
                        // console.log("conso")
                        this.props.callBackUserAuthentication(true);
                    });
            }
            catch (e) {
               // console.log("force password catch",e)
            }
        }
        else {
          //  console.log("confirm password doesnt match")
        }


    }


    handleForgotPassword = () => {
        this.setState({
            submitForgotUsername: true,
            passwordVisible: true
        });

    }

    render() {

        return (


            <Modal
                size={"tiny"}
                dimmer={"blurring"}
                open={this.state.isParentOpen}
                centered={false}
                closeIcon
                onClose={this.handleCloseForgotPasswordModal}
            >
                <Header icon='archive' content={this.state.modalContent} />
                <Modal.Content>
                    <Form >
                        <Form.Field>
                            <label
                                color='teal'
                            >
                                UserName
                                </label>
                            <input
                                placeholder='Enter you username'
                                readOnly={this.props.disable}
                                defaultValue={this.state.userName}
                                onChange={this.handleChange}
                                id='userName'
                            />
                            {this.state.submitForgotUsername ?
                                <div>
                                    <label >Enter the Verification Code that was sent to your email</label>
                                    <input placeholder='Enter the Verification Code' />
                                </div>
                                : null}
                            {this.state.passwordVisible ?

                                < div >
                                    
                                    <label >Enter new password</label>
                                    <input
                                        placeholder='Enter new password'
                                        type='password'
                                        defaultValue={this.state.password}
                                        onChange={this.handleChange}
                                        id='password'
                                    />
                                    <label >Confirm new password</label>
                                    <input
                                        placeholder='Confirm new password'
                                        type='password'
                                        defaultValue={this.state.confirmPassword}
                                        onChange={this.handleChange}
                                        id='confirmPassword'
                                    />

                                </div>
                                :null
                          }

                        </Form.Field>
                    </Form>
                </Modal.Content>
                <Modal.Actions>

                    <Button.Group size='large'>
                        {/*        <Button
                            basic color='black'
                            type='submit'

                        >
                            Cancel
                            </Button>
                        <Button.Or />       */}

                        {this.props.disable ?
                            <div>
                                <Button
                                    basic color='blue'
                                    type='submit'
                                    onClick={this.handleChangePassword}
                                >
                                    Submit
                            </Button>
                            </div>
                            :
                            !this.state.submitForgotUsername ?
                                <div>
                                    <Button
                                        basic color='blue'
                                        type='submit'
                                        onClick={this.handleForgotPassword}
                                    >
                                        Submit forgot
                            </Button>
                                </div>
                                :

                                <div>
                                    <Button
                                        basic color='green'
                                        type='submit'
                                        onClick={this.handleForgotPassword}
                                    >
                                        Submit forgot confirm
                            </Button>
                                </div>
                        }

                    </Button.Group>
                </Modal.Actions>
            </Modal>


        )
    }
}

function objectWithProperties(obj, keys) {
    const target = {};
    for (const key in obj) {
        if (keys.indexOf(key) === -1) {
            continue;
        }
        if (!Object.prototype.hasOwnProperty.call(obj, key)) {
            continue;
        }
        target[key] = obj[key];
    }
    return target;
}