

import React, { Component } from 'react'
import { Button, Header, Form, Icon, Label, Modal } from 'semantic-ui-react'
import ForgortPassword from './ForgotPassword'

export default class ChangePassword extends Component {
    state = { modalOpen: false }

    ChangePassword_handleOpen = () => this.setState({ modalOpen: true })

    ChangePassword_handleClose = () => this.setState({ modalOpen: false })

    render() {
        return (
            < Modal
                size={"tiny"}
                dimmer={"blurring"}
                trigger={< Button basic icon labelPosition='left' onClick={this.ChangePassword_handleOpen}>
                    <Icon name='key' />
                    Change Password</Button>}
                centered={false} closeIcon >


          






                <Header icon='archive' content='Change Password' />
                <Modal.Content>
                    <Form >
                        <Form.Field>
                            <label color='teal' >UserName  (Should come dynamically) </label>  {/* have to get this dynamically*/}
                            <label >Enter current password</label>
                            <input placeholder='Enter current password' type='password' />
                            <label >Enter new password</label>
                            <input placeholder='Enter new password' type='password' />
                            <label >Confirm new password</label>
                            <input placeholder='Confirm new password' type='password' />
                        </Form.Field>
                    </Form>
                </Modal.Content>
                <Modal.Actions>


                    <Button.Group size='large'>
                        <Button basic color='black' type='submit' onClick={this.ChangePassword_handleClose}>Cancel</Button>
                        <Button.Or />
                        <Button basic color='blue' type='submit'>Update</Button>
                    </Button.Group>
                </Modal.Actions>
            </Modal>
        )
    }
}