import React, { Component } from "react";
import HomeLayout from "./views/components/HomeLayout";
import { Auth } from "aws-amplify";
import LoginForm from "././views/components/LoginForm";
import { API } from "aws-amplify";
import "./App.css";

// class App extends Component {
//   render() {
//     return (
//       <div className="App">
//         <header className="App-header">
//           <img src={logo} className="App-logo" alt="logo" />
//           <p>
//             Edit <code>src/App.js</code> and save to reload.
//           </p>
//           <a
//             className="App-link"
//             href="https://reactjs.org"
//             target="_blank"
//             rel="noopener noreferrer"
//           >
//             Learn React
//           </a>
//         </header>
//       </div>
//     );
//   }
// }

// export default App;

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isAuthenticated: false,
      isAuthenticating: true //,
      //sessionDetails :[]
    };
  }

  async componentDidMount() {
    // console.log("isAuthenticated", this.state.isAuthenticated);
    //console.log("isAuthenticating", this.state.isAuthenticating);
    try {
      await Auth.currentSession();
      this.userHasAuthenticated(true);
      this.setState({ isAuthenticated: true });
    } catch (e) {
      if (e !== "No current user") {
        //alert(e);
        this.setState({ isAuthenticating: false });
      }
    }

   // this.setState({ isAuthenticating: false });
  }

  userHasAuthenticated = (authenticated) => {
    this.setState({ isAuthenticated: authenticated });
    //  console.log("USERisAuthenticated", this.state.isAuthenticated);
    //   console.log("authenticated", cognitoUser.signInUserSession.accessToken.jwtToken);

    if (authenticated === true) {
      //let session_id = "";
      //let signup_info = {
      //    user_data,
      //    session_id,
      //};
      //const headers = {
      //    "Access-Control-Allow-Origin": "*",
      //    "Access-Control-Allow-Credentials": true
      //};
      //console.log("  addUserDBHandler", headers);
      //return API.post("boatsecure", "/users", {
      //    headers: headers,
      //    body: signup_info
      //});
    }
  };

  handleLogout = async event => {
    await Auth.signOut();

    this.userHasAuthenticated(false);
  };

  render() {
    const childProps = {
      isAuthenticated: this.state.isAuthenticated,
      userHasAuthenticated: this.userHasAuthenticated
      //sessionDetails: this.state.sessionDetails
    };

    return !this.state.isAuthenticated ? (
      <LoginForm
        userHasAuthenticated={this.userHasAuthenticated}
        props={childProps}
      />
    ) : (
      <HomeLayout
        userHasAuthenticated={this.userHasAuthenticated}
        logOutHandler={this.handleLogout}
        props={childProps}
      />
    );
  }
}

{
  /*
const App = () => (
  <div>
    <HomeLayout /> 
     
     
      
  </div>
);
*/
}
export default App;
