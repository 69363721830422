import React from 'react';
import {   Image  } from 'semantic-ui-react';
const Home = () => (
    <div>
        <h1>Welcome to BoatSecure!</h1>
        <br />
        <Image src='https://react.semantic-ui.com/images/wireframe/paragraph.png' />
        <br />
        <Image src='https://react.semantic-ui.com/images/wireframe/paragraph.png' />
        <br />
        <Image src='https://react.semantic-ui.com/images/wireframe/paragraph.png' />
        <br />
    </div>
);

export default Home;

 